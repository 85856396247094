import { ProductProps } from 'tsconfig/types';
import { globals, smallProductImageUrl, largeProductImageUrl } from 'common-ui';
import { format } from 'date-fns';
import isEmpty from 'lodash/isEmpty';

//TODO: define types for inputs and response (e.g. see transformAtgCartToVineCart)
export const transformAtgProductToVineProduct = (data: ProductProps | undefined): any | undefined => {
  if (!data || isEmpty(data)) {
    return undefined;
  }

  // Set the default values from the API
  const { skus = [] } = data;

  function findValueByKey(data: any[]) {
    const matchingObject = data.find(item => item.key === globals.locale);
    return matchingObject ? matchingObject.value : '';
  }

  function setEnprimeurFlag(product: any, skus: any) {
    if (product?.enPrimeur) {
      return product.enPrimeur;
    }
    if (skus && skus.length > 0 && skus[0].preReleaseItem) {
      return skus[0].preReleaseItem;
    }
    return false;
  }

  function setStockQuantity(product: any) {
    if (product.stockQuantity) {
      return product.stockQuantity;
    }
    if (product?.inventoryInfo?.stockQuantity) {
      return product.inventoryInfo.stockQuantity;
    }
    return false;
  }

  function setHappyToWaitFlag(product: any) {
    if (product.happyToWaitEnabled) {
      return product.happyToWaitEnabled;
    }
    if (product?.inventoryInfo?.happyToWaitEnabled) {
      return product.inventoryInfo.happyToWaitEnabled;
    }
    return false;
  }

  function setAvailabilityDate(product: any) {
    if (product.availabilityDate) {
      return product.availabilityDate;
    }
    if (product?.inventoryInfo?.availabilityDate) {
      return product.inventoryInfo.availabilityDate;
    }
    return false;
  }

  function setEnPrimeurPrice(sku: any) {
    if (sku?.underbondPrice) {
      return sku.underbondPrice;
    }
    if (sku?.enPrimeurPrice) {
      return sku.enPrimeurPrice;
    }
    return false;
  }

  function setPresellFlag(product: any, skus: any) {
    if (product.preSell) {
      return product.preSell;
    }
    if (skus && skus.length > 0 && skus[0].preSellItem) {
      return skus[0].preSellItem;
    }
    return false;
  }

  function ProductItemData(data: any) {
    return {
      drinkByDate: data.drinkByDate ? format(new Date(data.drinkByDate), 'dd MMMM yyyy') : '',
      itemCode: data.itemCode ?? '',
      name: data.productName ? findValueByKey(data.productName) : '',
      description: data.productDescription ? findValueByKey(data.productDescription) : '',
      longDescription: data.productLongDescription ? findValueByKey(data.productLongDescription) : '',
      // subProductType: data.subProductType ? findValueByKey(data.subProductType) : '',
      webHeadline: data.webHeadline ? findValueByKey(data.webHeadline) : '',
      styleName: data.styleDescription ? findValueByKey(data.styleDescription) : '',
      grapeName: data.genGrapeDescription ? findValueByKey(data.genGrapeDescription) : '',
      appellationName: data.appellationRegionDescription ? findValueByKey(data.appellationRegionDescription) : '',
      regionName: data.genericRegionDescription ? findValueByKey(data.genericRegionDescription) : '',
      countryName: data.countryRegionDescription ? findValueByKey(data.countryRegionDescription) : '',
      colourName: data.colourCode ?? '',
      mapImageFile: data.mapImageFile ?? '',
      otherImageFile: data.otherImageFile ?? '',
      vintage: data.vintage ?? '',
      thumbnailImage: data.itemCode ? smallProductImageUrl(data.itemCode) : '',
      smallImage: data.itemCode ? smallProductImageUrl(data.itemCode) : '',
      largeImage: data.itemCode ? largeProductImageUrl(data.itemCode) : '',
      lowestPricePerBottle: '',
      // accolades: data.accolades ?? {},
      averageOverallRating: data.averageOverallRating ?? '',
      totalReviewCount: data.totalReviewCount ?? '',
      shortTastingNotesAroma: data.shortTastingNotesAroma ?? '',
      shortTastingNotesColor: data.shortTastingNotesColor ?? '',
      shortTastingNotesTaste: data.shortTastingNotesTaste ?? '',
      inventoryInfo: data.inventoryInfo ?? '',
      alcoholPercent: data.alcoholPercent ?? '',
      alcoholUnits: data.alcoholUnits ?? '',
      productType: data.productType ?? '',
      productWebType: data.productWebType ?? '',
      bottleType: data.bottleType ?? '',
      kosher: data.kosher ?? false,
      vegetarian: data.vegetarian ?? false,
      fineWineFlag: data.fineWineFlag ?? false,
      vegan: data.vegan ?? false,
      organic: data.organic ?? false,
      biodynamic: data.biodynamic ?? false,
      packagePioneer: data.packagePioneer ?? false,
      isMixed: data.packagePioneer ?? false,
      awardWinner: data.awardWinner ?? false,
      vppApplier: data.vppApplier ?? false,
      accolades: data.accolades ?? [],
      allergens: data.allergens ?? [],
      packaging: data.packaging ?? '',
      schemeDetails: data?.schemeDetails ?? {},
    };
  }

  const skusWithDefaults = skus
    .filter((sku: any) => sku?.saleable === true) // Filter out only saleable SKUs
    .filter((sku: any) => sku?.webEnabled === true) // Filter out only webEnabled SKUs
    .filter((sku: any) => sku?.stopFlag === false) // Filter out only stopFlag SKUs
    .map((sku: any) => {
      const mappedPresellPayments = sku.presellPayments.map((presell: any) => ({
        paymentDate: presell.paymentDate ?? '',
        stockDispatch: presell.stockDispatch ?? false,
        scheduleType: presell.scheduleType ?? '',
        paymentAmount: presell.paymentAmount ?? '',
      }));

      const {
        itemCode = '',
        pimCompanyCode = '',
        pimTimestamp = '',
        caseWebType = '',
        displayName = [{ key: '', value: '' }],
        skuDescription = [{ key: '', value: '' }],
        bottleVolume = 0,
        numberOfBottles = 0,
        buyersRRP = 0,
        salePrice = 0,
        enPrimeurPrice = setEnPrimeurPrice(sku), // Algolia = enPrimeurPrice / API = underbondPrice
        lowestPricePerBottle = 0,
        memberPrice = 0,
        vppAllowed = false,
        vppPrice = 0,
        referencedSkus = [],
        preSellItem = false,
        preReleaseItem = false,
        giftFlag = false,
        saleable = false,
        webEnabled = false,
        stockHeld = false,
        presellPayments = mappedPresellPayments || [],
      } = sku;

      const mappedReferencedSkus = referencedSkus.map((referencedSku: any) => ({
        itemId: referencedSku.itemId ?? '',
        quantity: referencedSku.quantity ?? '',
        expandedItem: {
          ...ProductItemData(referencedSku.expandedItem),
          skus: referencedSku.expandedItem.skus ?? [],
        },
      }));

      return {
        itemCode,
        pimCompanyCode,
        pimTimestamp,
        caseWebType,
        displayName,
        skuDescription,
        bottleVolume,
        numberOfBottles,
        buyersRRP,
        salePrice,
        enPrimeurPrice,
        lowestPricePerBottle,
        memberPrice,
        vppAllowed,
        vppPrice,
        referencedSkus: mappedReferencedSkus,
        preSellItem,
        preReleaseItem,
        giftFlag,
        saleable,
        webEnabled,
        stockHeld,
        presellPayments,
      };
    });

  // Map to the new values used in the UI
  return {
    skus: skusWithDefaults ?? [],
    ...ProductItemData(data),
    // some fields at product level come from the first sku
    // when we use the CT Product API so we map these individually
    // as the Algolia Product mapping is slightly different
    enPrimeur: setEnprimeurFlag(data, skusWithDefaults),
    stockQuantity: setStockQuantity(data), // Algolia = stockQuantity / API = inventoryInfo.stockQuantity
    happyToWaitEnabled: setHappyToWaitFlag(data), // Algolia = happyToWait / API = inventoryInfo.happyToWaitEnabled
    availabilityDate: setAvailabilityDate(data), // Algolia = availabilityDate / API = inventoryInfo.availabilityDate
    preSell: setPresellFlag(data, skusWithDefaults), // Algolia = preSell / API = inventoryInfo.preSellItem
  };
};
