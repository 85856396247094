import { css, useTheme } from '@emotion/react';
import { CaseSelector } from './CaseSelector';

export const CaseSelectors = (props: any) => {
  const { id, from, target, products, handleViewTabPanelClick, handleCaseClick, activeCase, activeBottomCase, upsellProduct, upsellSelected, handleUpsellClick } = props;

  const theme = useTheme();

  const cssCaseSelectors = css`
    ${theme.widgets?.CmsMultiCaseSelector?.caseSelectors}
  `;

  return (
    <div id={id} className='mcs-main-row mcs-selector-wrap' css={cssCaseSelectors} data-testid={id}>
      <div className='mcs-selector__header'>
        {from === 'target-panel' ? 
          <div className='h1'>What's in the case?</div>
          : 
          <>
            <span>Choose your case</span>
            <a href={target} className='eyebrow' onClick={e => handleViewTabPanelClick(e)}>What's in the Cases?</a>
          </>
        }
      </div>
      <div className={`mcs-selector__list ${from === 'target-panel' ? 'simple' : '' }`} role='tablist' aria-orientation='horizontal'>
        {products.map((item: any, index: number) => (
          <CaseSelector
            key={`case-selector-${index}`}
            item={item}
            index={index}
            from={from}
            view={from === 'target-panel' ? 'simple' : ''}
            activeCase={activeCase}
            activeBottomCase={activeBottomCase}
            handleClick={handleCaseClick}
          />
        ))}

        {upsellProduct && 
          <CaseSelector
            item={upsellProduct}
            index={from === 'target-panel' ? products.length : null}
            from={from}
            view={'simple'}
            type={'upsell'}
            activeCase={activeCase}
            activeBottomCase={activeBottomCase}
            upsellSelected={upsellSelected}
            handleClick={handleUpsellClick}
          />
        }
      </div>
    </div>
  );
};
