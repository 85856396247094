import { useState } from 'react';
import {
  Button,
  Alert,
  Loading,
  handleDLProductClickEvent,
  globals,
  Icon,
  handleAlgoliaEventForAddToCart,
} from 'common-ui';
import { useActionAddToCart, useActionBatchAddToCart, useRecoilValue, selectCartDetails, cartAtom } from 'common-state';
import { AxiosErrorResponse } from 'tsconfig/types';

export type CartProps = {
  itemCode: string;
  qty: string;
  buttonText?: string;
  sendEvent?: any;
  hit?: any;
  isDisable?: boolean;
  method?: string;
  cartItems?: any;
  fontWeight?: string;
  isUnlimitedWithMember?: boolean;
  upperCase?: boolean;
  closeModal?: () => void;
  buttonStyle?: 'primary' | 'secondary' | 'tertiary';
  isMiniCart?: boolean;
};

export const CartButton = (props: CartProps) => {
  const {
    itemCode,
    qty,
    buttonText = 'ADD TO BASKET',
    hit,
    isDisable,
    method,
    cartItems,
    fontWeight,
    isUnlimitedWithMember,
    upperCase,
    closeModal,
    buttonStyle = 'primary',
    isMiniCart,
  } = props;
  const { itemsCount } = useRecoilValue(selectCartDetails);
  const isCartDataLoaded = useRecoilValue(cartAtom);

  const [apiCallStatus, setApiCallStatus] = useState('idle');
  const [apiCallMessage, setApiCallMessage] = useState('');

  const addItemToCart = useActionAddToCart();
  const batchAddItemToCart = useActionBatchAddToCart();

  const handleApiError = (error: AxiosErrorResponse) => {
    if (error?.response?.data?.errorResponse?.message) {
      setApiCallMessage(error.response.data?.errorResponse?.message); // Provide a default value of an empty string
    } else {
      setApiCallMessage(error.message || 'There is an error adding to cart. Please try again.'); // Provide a default value of an empty string
    }
  };

  const handleAddToCartClick = async (cartItemCode: string, itemQty: string) => {
    if (itemQty === '') {
      setApiCallStatus('error');
      setApiCallMessage("Minimum quantity of '1' required");
      setTimeout(() => {
        setApiCallStatus('');
      }, 3000);
      return;
    }

    setApiCallStatus('loading'); // Set loading status while the API call is in progress

    try {
      // Add multiple items to the cart by including an additional object in the array for AWS cart API
      const cartItemsToAdd = [{ itemCode: cartItemCode, quantity: itemQty }];
      await addItemToCart(cartItemsToAdd);

      // If hit is not passed in as a prop, then we don't fire the cart events as the product data is required to be passed as part of the event
      if (hit) {
        handleAlgoliaEventForAddToCart(cartItemCode, itemQty, hit);

        // Adobe DataLayer
        const convertQtyToInt = parseInt(itemQty);
        if (itemsCount <= 0) {
          handleDLProductClickEvent('InitiateCart', hit, convertQtyToInt);
        } else {
          handleDLProductClickEvent('addToCart', hit, convertQtyToInt, ...(isMiniCart ? ['miniCart'] : []));
        }
      }

      setApiCallStatus('success'); // Set success status after a successful API call

      // Close the modal if it exists, after adding items to the cart
      if (closeModal) {
        closeModal();
      }
    } catch (error) {
      if (error) {
        handleApiError(error as AxiosErrorResponse);
      } else {
        // Handle the case where 'error' is not of type 'Error'
        setApiCallMessage('An error occurred.');
      }

      setApiCallStatus('error');
      setTimeout(() => {
        setApiCallStatus('');
      }, 5000);
    }
  };

  const handleBatchAddToCartClick = async (cartAddedItems: any) => {
    setApiCallStatus('loading'); // Set loading status while the API call is in progress

    try {
      if (globals?.useNewCartAPI) {
        await addItemToCart(cartAddedItems.cartItems);
      } else {
        await batchAddItemToCart(cartAddedItems);
      }

      // Adobe DataLayer
      const convertQtyToInt = parseInt(qty);

      // If hit is not passed in as a prop, then we don't fire the cart events as the product data is required to be passed as part of the event
      if (hit) {
        if (itemsCount <= 0) {
          handleDLProductClickEvent('InitiateCart', hit, convertQtyToInt);
        } else {
          handleDLProductClickEvent('addToCart', hit, convertQtyToInt, ...(isMiniCart ? ['miniCart'] : []));
        }
      }
      setApiCallStatus('success'); // Set success status after a successful API call

      // Close the modal if it exists, after adding items to the cart
      if (closeModal) {
        closeModal();
      }
    } catch (error) {
      if (error) {
        handleApiError(error as AxiosErrorResponse);
      } else {
        // Handle the case where 'error' is not of type 'Error'
        setApiCallMessage('An error occurred.');
      }
      setApiCallStatus('error');
      setTimeout(() => {
        setApiCallStatus('');
      }, 7500);
    }
  };

  return (
    <>
      <div className="cart-button">
        <Button
          kind={isUnlimitedWithMember ? 'primaryUnlimited' : buttonStyle}
          style={{ fontWeight }}
          onClick={() => {
            if (method === 'batch') {
              handleBatchAddToCartClick(cartItems);
            } else {
              handleAddToCartClick(itemCode, qty);
            }
          }}
          disabled={apiCallStatus === 'loading' || isDisable || (!isCartDataLoaded && globals?.useNewCartAPI)}
        >
          {apiCallStatus === 'loading' || (!isCartDataLoaded && globals?.useNewCartAPI) ? (
            <>
              <span className="loadIcon">
                <Loading />
              </span>
              <span className="addingText">{!isCartDataLoaded && globals?.useNewCartAPI ? 'Loading' : 'Adding'}</span>
            </>
          ) : (
            <span>
              {isUnlimitedWithMember && (
                <span className="unlimited-icon">
                  <Icon kind="unlimited" size="xs" />
                  &nbsp;&nbsp;
                </span>
              )}
              {upperCase ? buttonText.toUpperCase() : buttonText}
            </span>
          )}
        </Button>
      </div>
      {apiCallStatus === 'error' && (
        <div>
          <Alert type="error">{apiCallMessage}</Alert>
        </div>
      )}
    </>
  );
};
