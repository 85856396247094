import { useTheme } from '@emotion/react';
import { useEffect, useState } from 'react';
import { getBreakpoint } from '../utils/grid.utils';

/**
 * [useScreenLayout This hook returns the currently active breakpoint]
 *
 * @return  {[type]}  [return Breakpoints]
 */
export const useScreenLayout = () => {
  const theme = useTheme();

  const initialState = getBreakpoint(theme);
  const [{ screen }, setState] = useState({ screen: initialState });

  useEffect(() => {
    const setScreen = () => {
      const currentScreen = getBreakpoint(theme);

      if (currentScreen !== screen) {
        setState({
          screen: currentScreen,
        });
      }
    };

    setScreen();

    //SSR typecheck
    if (typeof window !== 'undefined') {
      window.addEventListener('orientationchange', setScreen, false);
      window.addEventListener('resize', setScreen, false);
    }

    return () => {
      window.removeEventListener('orientationchange', setScreen);
      window.removeEventListener('resize', setScreen);
    };
  }, [theme, screen]);

  const isDesktopView = ['lg', 'xl', 'xxl'].includes(screen);

  return { screen, isMobile: !isDesktopView, isDesktop: isDesktopView };
};
