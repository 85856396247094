import React from 'react';

const ChevronDown = () => (
  <svg width="93" height="57" viewBox="0 0 93 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M42.6743 54.5532L1.6225 13.4986C0.67426 12.5431 0.142151 11.2515 0.142151 9.90541C0.142151 8.55927 0.67426 7.26772 1.6225 6.31227L6.28066 1.65411C7.23034 0.68405 8.51581 0.115052 9.8724 0.0642718C10.5383 0.0179935 11.2062 0.118708 11.8288 0.359289C12.4515 0.599869 13.0136 0.97443 13.4753 1.45643L46.2661 34.25L79.0652 1.45643C79.5265 0.973807 80.0886 0.598832 80.7113 0.358205C81.334 0.117579 82.0022 0.0172193 82.6681 0.0642718C84.0257 0.114347 85.3123 0.6834 86.2627 1.65411L90.9208 6.31227C91.8691 7.26772 92.4012 8.55927 92.4012 9.90541C92.4012 11.2515 91.8691 12.5431 90.9208 13.4986L49.869 54.5532C49.4317 55.0839 48.8824 55.5114 48.2605 55.8048C47.6385 56.0982 46.9593 56.2504 46.2716 56.2504C45.584 56.2504 44.9048 56.0982 44.2829 55.8048C43.6609 55.5114 43.1116 55.0839 42.6743 54.5532Z"
      fill="#000"
    />
  </svg>
);

export default React.memo(ChevronDown);
