import { Tabs } from '@mantine/core';
import { smallProductImageUrl, ProductImage } from 'common-ui';

export const CaseTab = (props: any) => {
  const { product, count, quantity } = props;

  return (
    <Tabs.Tab
      value={`prod-item-${count + 1}`}
      icon={
        <ProductImage
          src={smallProductImageUrl(product.itemCode)}
          productType={product?.productType}
          alt={product?.name}
          fill={true}
        />
      }
    >
      x{quantity}
    </Tabs.Tab>
  );
};
