import Image from 'next/image';
import { Tabs } from '@mantine/core';
import { Rating } from 'common-ui/src/components/Rating/Rating';
import { globals, Icon, useDefaultImageHandling, AwardDetails, WineDetails, FlavourProfile, WineStyleCountryFlagAU, productLinkBuilder, TastingNote } from 'common-ui';

export const CaseTabContent = (props: any) => {
  const { product, count } = props;
  const renderSharePrint = () => {
    return (
      (product?.productType !== 'Sundry' || globals.country !== 'us') && (
        <div className="print-share-container no-print">
          <span className="label">Share / Print:</span>
          <span
            role="presentation"
            onClick={e => {
              e.preventDefault();
              window.open(
                `http://www.facebook.com/sharer.php?u=${globals.publicHost}${productLinkBuilder(
                  product?.name,
                  product?.vintage,
                  product?.itemCode,
                )}`,
                'popup',
                'width=800,height=600',
              );
            }}
          >
            <Icon kind="facebook" size="m" />
          </span>

          <span
            role="presentation"
            onClick={e => {
              e.preventDefault();
              window.open(
                `http://www.twitter.com/intent/tweet?url=${globals.publicHost}${productLinkBuilder(
                  product?.name,
                  product?.vintage,
                  product?.itemCode,
                )}`,
                'popup',
                'width=800,height=600',
              );
            }}
          >
            <Icon kind="x-twitter" size="xs" />
          </span>

          <a
            href={`mailto:?subject=I thought you may be interested in this &amp;body=${product.name} ${
              product.vintage
            } ${productLinkBuilder(product?.name, product?.vintage, product?.itemCode)}`}
          >
            <Icon kind="envelope" size="m" />
          </a>

          <TastingNote product={product} />
        </div>
      )
    );
  };
  const { imageSrc, handleImageError } = useDefaultImageHandling(
    product?.largeImage,
    `/next/images/default_images/bottle.png`,
  );

  return (
    <Tabs.Panel value={`prod-item-${count + 1}`} className="wineDetailsSection">
      <div className="header-container">
        <h2 className="main-title">
          <a href={productLinkBuilder(product.name, product.vintage, product.itemCode)}>
            {product.name} {product.vintage}
          </a>
        </h2>
        {product?.skus && product.skus.length > 0 && (
          <div className="price">
            Regular price: {globals.currency}
            {product?.skus[0]?.salePrice} per bottle
          </div>
        )}
      </div>
      {product?.averageOverallRating && (
        <div className="rating-container">
          <Rating rating={product?.averageOverallRating} />
          {product?.totalReviewCount && (
            <div className="rating-cout">
              <a href={`${productLinkBuilder(product.name, product.vintage, product.itemCode)}#product-reviews`}>
                {product?.totalReviewCount} {product?.totalReviewCount <= 1 ? 'review' : 'reviews'}
              </a>
            </div>
          )}
        </div>
      )}

      <div className="web-description">{product?.webHeadline}</div>
      {globals.country === 'au' && (
        <>
          <WineStyleCountryFlagAU product={product} /> <AwardDetails product={product} />
        </>
      )}
      <div className="case-content-details-section">
        <div className='wine-details'>
          <h2 className="sub-title-with-border">Wine Details</h2>
          {globals.country === 'us' && product?.accolades?.length > 0 && <AwardDetails product={product} />}
          <WineDetails product={product} />
        </div>
        <div className="case-image">
          <Image src={imageSrc} alt={product.name} width={80} height={300} onError={handleImageError} />
        </div>
        <div className="case-details">
          <div className="flavour-section">
            <h2 className="sub-title-with-border">{globals.country === 'us' ? 'Flavor' : 'Flavour'} Profile</h2>

            <FlavourProfile
              product={{
                shortTastingNotesTaste: product.shortTastingNotesTaste,
                shortTastingNotesAroma: product.shortTastingNotesAroma,
                allergens: product.allergens,
              }}
            />
          </div>
        </div>
        {globals.country === 'us' ? <div className="share-print">{renderSharePrint()}</div> : null}
      </div>
    </Tabs.Panel>
  );
};
