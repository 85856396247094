import React from 'react';

const ChevronUp = () => (
  <svg width="93" height="57" viewBox="0 0 93 57" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M49.7292 1.75435L90.781 42.8145C91.7291 43.7718 92.261 45.0646 92.261 46.4118C92.261 47.7591 91.7291 49.0519 90.781 50.0092L86.1229 54.659C85.1748 55.633 83.8868 56.2027 82.5283 56.2488C81.864 56.2968 81.1973 56.1988 80.5748 55.9616C79.9524 55.7245 79.3894 55.354 78.9254 54.8761L46.1263 22.0742L13.3355 54.8706C12.871 55.3477 12.3079 55.7177 11.6857 55.9547C11.0634 56.1918 10.3969 56.2903 9.73265 56.2432C8.37417 56.1971 7.08615 55.6275 6.13811 54.6534L1.47995 50.0036C0.531863 49.0464 0 47.7536 0 46.4063C0 45.059 0.531863 43.7662 1.47995 42.809L42.5318 1.75435C42.969 1.22359 43.5183 0.796169 44.1403 0.502734C44.7622 0.2093 45.4414 0.0571289 46.1291 0.0571289C46.8168 0.0571289 47.496 0.2093 48.1179 0.502734C48.7399 0.796169 49.2891 1.22359 49.7264 1.75435H49.7292Z"
      fill="#000"
    />
  </svg>
);

export default React.memo(ChevronUp);
