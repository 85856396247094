import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import ContainerLayout from '../../../layouts/ContainerLayout';
import { useRecoilValue, selectCartDetails } from 'common-state';
import {
  HeaderNavigationEntryType,
  TabsEntryType,
  LinkEntryType,
  ArrayEntryType,
  SiteEntryType,
  DLClickEventHandlerProps,
} from 'tsconfig/types';
import StateSelector from './StateSelector/StateSelector';
import { globals } from '../../../utils/globals';
import {
  StickyMainNavBar,
  MainNavMobile,
  HeaderSearchBar,
  HeaderSignIn,
  HeaderFavourites,
  HeaderLogo,
  HeaderCart,
  Grid,
} from 'common-ui';

export type SearchNavBarProps = {
  cmsAccountNav?: HeaderNavigationEntryType;
  cmsMainNav?: ArrayEntryType<TabsEntryType>;
  cmsFavourite?: LinkEntryType;
  globalHeaderHeight: number;
  sticky?: boolean;
  enableCurityLogin?: boolean;
  footerNavBar?: SiteEntryType['fields']['footerNavBar'];
  socialLinks?: ArrayEntryType<LinkEntryType>;
  badgeImages?: ArrayEntryType<LinkEntryType>;
  dlClickEventHandler: DLClickEventHandlerProps;
};

export const SearchNavBar = memo((props: SearchNavBarProps) => {
  const {
    cmsAccountNav,
    cmsMainNav,
    socialLinks,
    footerNavBar,
    cmsFavourite,
    badgeImages,
    sticky,
    enableCurityLogin,
    globalHeaderHeight,
    dlClickEventHandler,
  } = props;
  const { itemsCount, cartTotal, hasQuantity } = useRecoilValue(selectCartDetails);
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.GlobalHeader?.searchNavBar}
  `;

  return (
    <div data-test="widget-SearchNavBar" css={wrapper} id="search-nav-bar">
      <ContainerLayout className="text-right">
        <div className="search-navbar-container">
          <Grid justify="between" align="center" className="grid-search-navbar-container">
            <Grid.Col xs={4} className="navbar-left-container">
              <HeaderLogo />
            </Grid.Col>
            <Grid.Col xs={sticky ? 'auto' : 4} className="navbar-center-container">
              <MainNavMobile
                cmsMainNav={cmsMainNav}
                footerNavBar={footerNavBar}
                socialLinks={socialLinks}
                badgeImages={badgeImages}
                dlClickEventHandler={data => {
                  dlClickEventHandler(data, 'mainNavClick');
                }}
              />
              <StickyMainNavBar
                globalHeaderHeight={globalHeaderHeight}
                cmsMainNav={cmsMainNav}
                dlClickEventHandler={data => {
                  dlClickEventHandler(data, 'mainNavClick');
                }}
              />
              <HeaderSearchBar isSticky={sticky} />
            </Grid.Col>
            <Grid.Col xs={sticky ? 'auto' : 4} className="navbar-right-container">
              <Grid align="center" justify="end">
                {/* State Selector only for US */}
                {globals.country === 'us' && (
                  <Grid.Col className="py-0 state-selector" xs="auto">
                    <StateSelector view="mobile" />
                  </Grid.Col>
                )}
                {cmsFavourite && cmsFavourite?.fields?.url !== '' && (
                  <Grid.Col className="py-0 favourites-col-container" xs="auto">
                    <HeaderFavourites cmsFavourite={cmsFavourite} />
                  </Grid.Col>
                )}
                <Grid.Col className="py-0" xs="auto">
                  <HeaderSignIn enableCurityLogin={enableCurityLogin} cmsAccountNav={cmsAccountNav} />
                </Grid.Col>
                <Grid.Col className="py-0" xs="auto">
                  <HeaderCart itemsCount={itemsCount} hasQuantity={hasQuantity} total={cartTotal} />
                </Grid.Col>
              </Grid>
            </Grid.Col>
          </Grid>
        </div>
      </ContainerLayout>
    </div>
  );
});
