import type { ProductProps } from 'tsconfig/types';
import { largeProductImageUrl, smallProductImageUrl } from '../utils/productImage';
interface AccoladeProps {
  accoladeLevelDescription: string;
  accoladeOrgDescription: string;
  accoladeTypeDescription: string;
}

export const adobeProductMapData = (data: ProductProps, quantity: number, isFreeGift = false) => {
  /**
   * Sets the accolades for the given data.
   * @param data - The data containing accolades.
   * @returns An array of filtered accolades or false if data.accolades is falsy.
   */
  function setAccolades(accoladeData: any) {
    if (accoladeData?.accolades) {
      return accoladeData?.accolades?.map((item: AccoladeProps) => ({
        accoladeDescription: item?.accoladeLevelDescription,
        accoladeNameText: item?.accoladeOrgDescription,
        accoladeCategoryText: item?.accoladeTypeDescription,
      }));
    }
    return false;
  }

  function calculatePrice(productData: ProductProps): number {
    const { enPrimeur, enPrimeurFlag, skus } = productData;

    const targetSku = skus?.find(eachSku => (enPrimeur || enPrimeurFlag) && typeof eachSku.enPrimeurPrice === 'number');

    // Return enPrimeurPrice if found, otherwise return the first available buyersRRP
    return targetSku?.enPrimeurPrice ?? skus?.[0]?.buyersRRP ?? 0;
  }

  return {
    accolades: setAccolades(data),
    attributes: {
      organic: data?.organic || '',
      vegan: data?.vegan || '',
      vegetarian: data?.vegetarian || '',
    },
    colour: data?.colourName || data?.colour || '',
    country: data?.countryName || data?.country || '',
    discount: isFreeGift
      ? data?.skus?.[0]?.buyersRRP
      : (data?.skus?.[0]?.buyersRRP - data?.skus?.[0]?.salePrice).toFixed(2),
    enPrimeur: data?.enPrimeur || data?.enPrimeurFlag || false,
    favourited: data?.favourited || '',
    grape: data?.grapeName || data?.grape || '',
    isMixed: data?.isMixed || data?.packagingPioneer || false,
    largeImage: data?.largeImage || (data?.skus?.[0]?.itemCode ? largeProductImageUrl(data?.itemCode) : ''),
    name: data?.name || data?.productName || '',
    noBottles: data?.skus?.[0]?.numberOfBottles || 0,
    noReviews: data?.totalReviewCount || data?.numberOfReviews || 0,
    price: calculatePrice(data),
    rating: data?.averageOverallRating || data?.avgRating || 0,
    salesActivity: data?.salesActivity || '',
    sku: data?.itemCode || '',
    smallImage: data?.smallImage || (data?.skus?.[0]?.itemCode ? smallProductImageUrl(data?.itemCode) : ''),
    stockAmount: data?.inventoryInfo?.stockQuantity || data?.stockQuantity || '',
    stockAvailability: data?.inventoryInfo?.availabilityStatus || data?.availabilityStatus || '',
    subType: data?.productWebType || '',
    thumbnailImage: data?.thumbnailImage || '',
    type: data?.productType || '',
    units: quantity,
    vintage: data?.vintage || '',
    vppApplier: data?.skus?.[0]?.vppApplier || '',
    vppPrice: data?.skus?.[0]?.vppPrice || '',
    vppQualifier: !!data?.skus?.[0]?.vppAllowed,
    webHeadline: data?.webHeadline || '',
  };
};
