import { initAtgLegacyMiscApi } from 'common-api/src/directWinesApis/atgLegacyMiscApi';
import { LoginType } from 'tsconfig/types';

export const allLoginType = ['hard_logged', 'soft_logged', 'unidentified'];

type UserStatus = {
  loginStatus: string;
  loginType: LoginType;
};

export const userStatus = (securityStatus?: number): UserStatus => {
  switch (securityStatus) {
    case 5:
    case 4:
      return {
        loginStatus: 'logged in',
        loginType: 'hard_logged',
      };
    case 3:
    case 2:
      return {
        loginStatus: 'logged in',
        loginType: 'soft_logged',
      };
    default:
      return {
        loginStatus: 'not logged in',
        loginType: 'unidentified',
      };
  }
};

export const logout = (type: LoginType) => {
  const commerceApi = initAtgLegacyMiscApi();
  const logoutMethod = type === 'soft_logged' ? commerceApi.User.softLogout : commerceApi.User.hardLogout;
  logoutMethod()
    .then(data => {
      const url = data.split('"')[1];
      window.location.href = url;
    })
    .catch(err => {
      if (err.response) {
        console.log(err);
      }
    });
};
