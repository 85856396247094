import { useRef, useEffect } from 'react';

export const useToggleClass = (enabledClass: string, disabledClass: string, isEnabled: boolean) => {
  const toggledElemRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (toggledElemRef.current) {
      if (isEnabled) {
        toggledElemRef.current?.classList.remove(disabledClass);
        toggledElemRef.current?.classList.add(enabledClass);
      } else if (toggledElemRef.current?.classList.contains(enabledClass)) {
        toggledElemRef.current?.classList.remove(enabledClass);
        toggledElemRef.current?.classList.add(disabledClass);
      }
    }
  }, [isEnabled, enabledClass, disabledClass]);

  return { toggledElemRef };
};
