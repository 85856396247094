// Wrapper for the Temp AWS API
// This is include API endbpoints from early in the migration work that while they are AWS
//based, they use an intermediate bearer token auth mechanism
// This API is in the progress of being migrated to the new AWS (WebApi) API
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { logApiRequest } from '../utils/logging';
import { UserSubscriptions } from 'tsconfig/types';

type AwsLegacyApiConfig = {
  urlPrefix: string;
  extraHeaders?: Record<string, string>;
  brandToken?: string;
  userToken?: string;
  enableLogging?: boolean;
};

export const initAwsTempApi = (config: AwsLegacyApiConfig) => {
  async function asyncRequest(requestType: string, url: string, token?: string, data = {}): Promise<any> {
    let requestConfig: AxiosRequestConfig = {},
      response: AxiosResponse | undefined,
      incomingRequestTime: Date | undefined,
      outgoingResponseTime: Date | undefined;

    try {
      incomingRequestTime = new Date();
      const requestUrl = config.urlPrefix + url;
      requestConfig = {
        method: requestType,
        url: requestUrl,
        headers: {
          'content-type': 'application/json',
          ...config.extraHeaders,
        },
        data: data,
      };

      if (token) {
        requestConfig.headers!.Authorization = `Bearer ${token}`;
      }

      // TODO: Remove this condition after updating to NEXTJS 14
      if (requestType === 'DELETE' || (requestType === 'POST' && Object.keys(data).length === 0)) {
        requestConfig.data = '';
      }

      response = await axios.request(requestConfig);
      return (response && response!.data) || {};
    } catch (e: any) {
      //rethrow error for caller to handle
      throw e;
    } finally {
      outgoingResponseTime = new Date();
      if (config.enableLogging) {
        //Note: may be better to throw/pass back this info so caller can log (+ add meaningful message)?
        logApiRequest(requestConfig, response, incomingRequestTime, outgoingResponseTime, 'AwsLegacyApi');
      }
    }
  }

  const userPreferencesApiPrefix = '/wine-preferences/user-preferences/';
  const myUserPreferenceApiPrefix = '/wine-preferences/me/user-preferences';

  return {
    BrandPreferences: {
      get: async () => await asyncRequest('GET', '/wine-preferences/brand-preferences', config.brandToken),
    },
    UserPreferences: {
      get: async (params: any) => await asyncRequest('GET', userPreferencesApiPrefix + params.userId, config.userToken),
      post: async (params: any, data: any) =>
        await asyncRequest('POST', userPreferencesApiPrefix + params.userId, config.userToken, data),
      put: async (params: any, data: any) =>
        await asyncRequest('PUT', userPreferencesApiPrefix + params.userId, config.userToken, data),
    },
    MyUserPreferences: {
      get: async () => await asyncRequest('GET', myUserPreferenceApiPrefix, config.userToken),
      post: async (data: any) => await asyncRequest('POST', myUserPreferenceApiPrefix, config.userToken, data),
      put: async (data: any) => await asyncRequest('PUT', myUserPreferenceApiPrefix, config.userToken, data),
    },
    Product: {
      get: async (countryBrand: string, itemCode: string) => {
        return await asyncRequest('GET', `/products/in-brand/${countryBrand}/${itemCode}`);
      },
      compliance: async (stateCode: string, itemCode: string, brandKey: string, complianceType: string) => {
        return await asyncRequest(
          'GET',
          `/products/in-brand/${brandKey}/compliance/${itemCode}?stateCode=${stateCode}&complianceType=${complianceType}`,
        );
      },
    },
    Subscription: {
      get: async (): Promise<UserSubscriptions> => await asyncRequest('GET', `/subscriptions/me/user-subscriptions`),
      updateAutoRenew: async (subId: number, data: any) =>
        await asyncRequest('POST', `/subscriptions/me/user-subscriptions/${subId}`, config?.userToken, data),
      getRenewalOrdersList: async (subId: number | string) =>
        await asyncRequest('GET', `/subscriptions/renewal-orders/${subId}`),
      getCancelReason: async (market: string, subscriptionType: string, type: string) =>
        await asyncRequest('GET', `/subscriptions/reasons/${market}/${subscriptionType}/${type}`),
      getCancelOffers: async (subId: string, saveType: string) =>
        await asyncRequest('GET', `/subscriptions/me/user-subscriptions/${subId}/offers/${saveType}`),
      applyUnlimitedOffer: async (subId: string, saveType: string, data: { offerId: string }) =>
        await asyncRequest(
          'POST',
          `/subscriptions/me/user-subscriptions/${subId}/offers/${saveType}`,
          config?.userToken,
          data,
        ),
    },
  };
};
