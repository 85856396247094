import { css, useTheme } from '@emotion/react';
import { Modal } from '@mantine/core';
import { smallProductImageUrl, largeProductImageUrl } from '../../utils/productImage';
import { ProductPropsTransformed } from 'tsconfig/types';
import { ProductImage } from '../ProductImage/ProductImage';

interface ZoomProps {
  product: ProductPropsTransformed;
  opened: boolean;
  fullScreen?: boolean;
  handleOnClose: () => void;
}

const Zoom = (props: ZoomProps) => {
  const { product, opened, handleOnClose, fullScreen = false } = props;
  const src =
    product?.productType === 'Case' ? smallProductImageUrl(product.itemCode) : largeProductImageUrl(product.itemCode);

  const theme = useTheme();

  const wrapper = css`
    ${theme.widgets.Product?.ItemCard}
  `;

  return (
    <Modal
      opened={opened}
      css={wrapper}
      onClose={() => handleOnClose()}
      fullScreen={fullScreen}
      transitionProps={{ transition: 'fade', duration: 200 }}
    >
      <div className="zoom-container">
        <ProductImage src={src} productType={product?.productType} alt={product.productName} fill={true} />
      </div>
    </Modal>
  );
};

export default Zoom;
