import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { ArrayEntryType, DLClickEventHandlerProps, TabsEntryType } from 'tsconfig/types';
import { MainNavBar } from '../MainNavBar/MainNavBar';
import { HeaderLogo } from '../SearchNavBar/HeaderLogo/HeaderLogo';

export type MainNavBarProps = {
  cmsMainNav?: ArrayEntryType<TabsEntryType>;
  globalHeaderHeight: number;
  dlClickEventHandler: DLClickEventHandlerProps;
};

export const StickyMainNavBar = memo(({ globalHeaderHeight, cmsMainNav, dlClickEventHandler }: MainNavBarProps) => {
  const theme = useTheme();
  const widgetTheme = theme.widgets.GlobalHeader || {};

  const wrapper = css`
    ${widgetTheme?.stickymainNavWrapper}
  `;

  return (
    <div css={wrapper} className="sticky-nav-bar">
      <HeaderLogo isSticky={true} />
      <MainNavBar
        globalHeaderHeight={globalHeaderHeight}
        cmsMainNav={cmsMainNav}
        dlClickEventHandler={dlClickEventHandler}
      />
    </div>
  );
});
