import { useTheme } from '@emotion/react';
import { Menu } from '@mantine/core';
import React, { memo, ReactNode } from 'react';

export type MenuDropdownProps = {
  target?: ReactNode;
  targetContent?: ReactNode;
  dropdownClassName?: string;
  hover: boolean;
};

export const MenuDropdown = memo((props: MenuDropdownProps) => {
  const { target, targetContent, dropdownClassName, hover } = props;
  const theme = useTheme();
  const wrapper = theme.widgets?.MenuDropdown;

  return (
    <Menu
      classNames={{ dropdown: dropdownClassName }}
      id="mantine-menu"
      styles={wrapper}
      withArrow
      arrowSize={13}
      trigger={hover ? 'hover' : 'click'}
    >
      <Menu.Target>
        <div>{target}</div>
      </Menu.Target>
      <Menu.Dropdown>{targetContent}</Menu.Dropdown>
    </Menu>
  );
});
