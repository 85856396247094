import React from 'react';
import { Tabs } from '@mantine/core';
import { useTheme } from '@emotion/react';
import { CmsRichText } from '../CmsRichText/CmsRichText';
import { TabEntryType, RichTextEntryType } from 'tsconfig/types.d';

export type CmsTabsProps = {
  tabPanes: TabEntryType[];
};

export const CmsTabs = (props: CmsTabsProps) => {
  const theme = useTheme();
  const { tabPanes } = props;

  return (
    <Tabs id="cms-tabs" keepMounted={false} defaultValue={tabPanes[0]?.sys?.id} styles={theme?.widgets?.CmsTabs}>
      <Tabs.List id="cms-tabs-list">
        {tabPanes?.map((tabpane: TabEntryType) => (
          <Tabs.Tab
            id={`tabs-tab-${tabpane?.sys?.id}`}
            value={tabpane?.sys?.id}
            className={tabpane?.fields?.emphasizeTitle ? 'highlighted' : ''}
            key={`tabs-tab-${tabpane?.sys?.id}`}
          >
            {tabpane?.fields?.title}
          </Tabs.Tab>
        ))}
      </Tabs.List>

      {tabPanes?.map((tabpane: TabEntryType) => (
        <Tabs.Panel
          aria-labelledby={`tabs-tab-${tabpane?.sys?.id}`}
          id={`tabs-panel-${tabpane?.sys?.id}`}
          value={tabpane?.sys?.id}
          key={`tabs-panel-${tabpane?.sys?.id}`}
        >
          {tabpane?.fields?.content && <CmsRichText contentItem={tabpane?.fields?.content[0] as RichTextEntryType} />}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};
