import { isBrowser } from './isBrowser';

function hexToRgb(hex: string): Record<'r' | 'g' | 'b', number> {
  hex = hex.replace(/^#/, '');

  // Parse the hex color string
  const bigint = parseInt(hex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;

  return { r, g, b };
}

export default function getPixalImageDataUrl(hexColor: string): string {
  if (!isBrowser()) {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8+x8AAr8B3gzOjaQAAAAASUVORK5CYII=';
  }
  // Convert hex color to RGB
  try {
    const { r, g, b } = hexToRgb(hexColor);

    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = 1;
    canvas.height = 1;

    // Get the 2D rendering context
    const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

    // Set the fill style to the specified color
    ctx.fillStyle = `rgb(${r},${g},${b})`;

    // Fill the canvas with the color
    ctx.fillRect(0, 0, 1, 1);

    // Get the base64 PNG data URL
    const base64Png = canvas.toDataURL('image/png');

    // Return the base64 PNG data URL
    return base64Png;
  } catch {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mO8+x8AAr8B3gzOjaQAAAAASUVORK5CYII=';
  }
}
