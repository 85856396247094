import { ATGLegacyCartResponse, VineCart } from 'tsconfig/types.d';
import { setLineItems } from './transformAwsCartToVineCart';

export const transformAtgCartToVineCart = (atgCartData: ATGLegacyCartResponse | undefined): VineCart => {
  const version = 0;
  const id = atgCartData?.response?.id || '';
  const totalLineItems = atgCartData?.response?.numItems || 0;
  const numberOfBottles = atgCartData?.response?.numBottles || 0;
  const responseCode = '';
  const totalPrice = {
    amount: atgCartData?.response?.orderPriceInfo?.rawSubtotal || 0,
    currencyCode: atgCartData?.response?.orderPriceInfo?.currencyCode || '',
  };
  const shippingInformation = {
    shippingMethodName: '',
    price: {
      amount: 0,
      currencyCode: '',
    },
    shippingMethodKey: '',
  };

  return {
    version,
    id,
    totalLineItems,
    numberOfBottles,
    responseCode,
    lineItems: setLineItems(atgCartData),
    totalPrice,
    shippingInformation,
  };
};
