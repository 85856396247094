import { useEffect, useState } from 'react';
import { isBrowser } from '../utils/isBrowser';

/**
 * Recat hook that return true if screensize is under givan breakpoint.
 */

export function useInBreakpoint(breakpoint = 768) {
  const getIsMobileWidth = () => {
    if (isBrowser()) {
      const { innerWidth: width } = window;
      return breakpoint >= width;
    }
    return false;
  };

  const [isMobileWidth, setIsMobileWidth] = useState<boolean>(getIsMobileWidth());

  useEffect(() => {
    function handleResize() {
      setIsMobileWidth(getIsMobileWidth());
    }

    isBrowser() && window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isMobileWidth;
}
