import { atom, selector, useRecoilState } from 'recoil';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';

export enum NotificationType {
  ADD_TO_CART = 'ADD_TO_CART',
  REMOVED_FROM_CART = 'REMOVED_FROM_CART',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
}
export interface NotificationState {
  type: NotificationType;
  message?: string;
  timeout: number | null;
  expires: number;
  miniCart?: {
    itemCode: string;
    quantity: string;
  };
}

//Atom
//----------------------------------------------------------------------
export const notificationsAtom = atom({
  key: 'notificationsAtom',
  default: [] as NotificationState[],
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------
export const selectAddToCartNotification = selector({
  key: 'selectAddToCartNotification',
  get: ({ get }) => {
    const notifications = get(notificationsAtom);
    return notifications.filter((notification: NotificationState) =>
      [NotificationType.ADD_TO_CART, NotificationType.REMOVED_FROM_CART].includes(notification.type),
    )[0];
  },
});

//Hooks
//----------------------------------------------------------------------
export const useInitNotificationsState = () => {
  const initNotificationsState = async () => {};
  return initNotificationsState;
};

export function useAddNotification(notificationType: NotificationType, timeout: number, message = '') {
  const [currentNotifications, setNotifications] = useRecoilState<NotificationState[]>(notificationsAtom);
  const newNotification: NotificationState = {
    type: notificationType,
    message: message,

    timeout: timeout,
    // created: new Date().getTime(),
    expires: new Date().getTime() + timeout,
  };
  setNotifications([newNotification, ...currentNotifications]);
}
