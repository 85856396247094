/**
 * Theme for Laithwaites US site
 */
import type { Theme } from '@emotion/react';
import { buildTheme } from 'common-ui';
import merge from 'lodash/merge';
import { icons } from 'common-font-icons';
import type { CommonThemeVars, ThemeCreateFunction } from 'tsconfig/types';
import { buildThemeMixinsLW, buildThemeVarsLW, buildThemeWidgetStylesLW } from 'dw-uk-law/theme';

export const buildThemeVarsUsLW = (vars: Pick<Theme, CommonThemeVars>) => {
  const lwGrey400 = '#BCBCBC';
  const lwGrey700 = '#616161';
  const lwPink500 = '#E2004D';
  const lwUnlimitedMemberText = '#0055A0';
  const lwCTAGreen = '#117B53';
  const lwJoinWhite = '#FFFFFF';
  const unlimitedPopupbackground = '#112B4B';
  const lwBlue600 = '#0055A0';
  const lwFilterSubCategoryColor = '#303030';
  const lwGrey200 = '#EDEDED';
  const lwBlue = '#004D86';
  const lwBlue700 = '#112b4b';
  const whiteWine = '#E6EFF7';
  const lwRed500 = '#E92440';
  const lwUnlimitedButtonColor = '#112b4b';

  const colors = {
    secondary9: lwGrey400,
    btnsWhiteBg: lwJoinWhite,
    btnsFilterSubCategoryColor: lwFilterSubCategoryColor,
    radioBtnsPrimaryBg: lwCTAGreen,
    textPrimaryBg: lwPink500,
    textSecondayBg: lwGrey700,
    textUnlimitedBg: lwUnlimitedMemberText,
    textShippingCharges: lwBlue,
    presellTableHeaderBg: lwGrey200,
    unltPopupBackground: unlimitedPopupbackground,
    blue600: lwBlue600,
    btnsPrimaryBg: lwCTAGreen,
    whiteWine: whiteWine,
    saleTagBg: lwRed500,
    unlimitedButtonColor: lwUnlimitedButtonColor,
    unltPopupColor: vars.colors.white,
    untlPopupButtonColor: vars.colors.black,
    untlPopupButtonBgColor: vars.colors.white,
    unltPopupcloseBtn: vars.colors.white,
    cardBackground: lwBlue700,
  };

  const space = {
    //common spacing based on design guide grid/typography/buttons etc
    xs: '4px',
    sm: '8px',
    md: '10px',
    lg: '16px',
    xl: '24px',
    xl2: '32px',
    xl3: '48px',
    xl4: '64px',
  };

  const lwThemeVars = buildThemeVarsLW(vars);

  return merge({}, lwThemeVars, {
    meta: {
      name: 'Laithwaites US',
    },
    icons,
    fonts: {
      familyTertiary: lwThemeVars.fonts.noto,
      sizeSmaller: '12px',
      sizeSmall: '14px',
      sizeLarge: '16px',
      sizeH6: '18px',
      sizeH7: '20px',
      sizeH8: '22px',
      sizeH9: '26px',
      sizeH10: '30px',
      weightSmall: '400',
      weightMediumPlus: '600',
      weightBold: '700',
    },
    colors,
    space,
  });
};
export const buildThemeMixinsUsLW = (vars: Omit<Theme, 'mixins' | 'widgets'>) => {
  return buildThemeMixinsLW(vars);
};
export const buildThemeWidgetStylesUsLW = (vars: Omit<Theme, 'widgets'>) => {
  const buildThemeWidget = buildThemeWidgetStylesLW(vars);

  return merge({}, buildThemeWidget, {
    Footer: {
      default: `
      ${buildThemeWidget.Footer.default}
      @media print {
        display: none;
      }
      .footer-bottom-section .wrapper .copyrights p{
        border: none;
        padding: 0px;
      }
      ${vars.breakpoints.lg} {
        .footer-bottom-section .wrapper .bedrinkaware-group {
          justify-content: flex-start;
        }
      }
      `,
    },
    Search: {
      quickViewWrapper: `
      ${buildThemeWidget.Search.quickViewWrapper}
      .mantine-Modal-content { 
        .mantine-Modal-title{
          margin-bottom: 0px;
          margin-top: 10px;
        }
        .mantine-Modal-body {
          padding: 0 1rem;
          .description {
            font-size: 16px;
            margin: 16px 0;
          }
          .row.shipping-charges{
            font-size: ${vars.fonts.sizeSmall};
            color: ${vars.colors.textShippingCharges};
            span{
              padding-left: 8px;
            }
          }
          .table-container {
            border: 1px solid ${vars.colors.grey60};
            border-radius: 4px;
            margin: 30px 0 15px;
            font-size: 16px;
            .row {
              border: none;
            }
            .column {
              padding: 12px 16px;
              font-size: 16px;
              border: 1px solid ${vars.colors.grey60};
              background-color: ${vars.colors.white};
            }
            header {
              .column {
                background-color: ${vars.colors.presellTableHeaderBg};
              }
            }
          }
        }
      }
      `,

      refinements: `
      ${buildThemeWidget.Search.refinements}
      .heading{
        button{
          color: ${vars.colors.btnsFilterSubCategoryColor}
        }
      }
      ul.ais-RefinementList-list {
        li {
          &.ais-RefinementList-item {
            .ais-RefinementList-label {

            }
            .ais-RefinementList-labelText {
              color: ${vars.colors.btnsFilterSubCategoryColor}
            }
            .ais-RefinementList-count {
              color: ${vars.colors.btnsFilterSubCategoryColor}
            }
          }
        }
      }  
      `,
      wrapper: `
      ${buildThemeWidget.Search.wrapper}
        .filter-header {
          color: ${vars.colors.grey50};
        }
      `,
      hitWrapper: ` 
      ${buildThemeWidget.Search.hitWrapper}

      .ais-Highlight-nonHighlighted, .country, .web-description {
        color: ${vars.colors.grey50};
      }
    
      &.list-view { 
        .ais-Hits-list {
          .ais-Hits-item {

            ${vars.breakpoints.xs} {
              // display: block;
              grid-template-columns: none;
            }

            ${vars.breakpoints.sm} {
              grid-template-columns: 344px auto;
            }
            ${vars.breakpoints.md} {
              grid-template-columns: 350px auto;
            }
            ${vars.breakpoints.lg} {
              grid-template-columns: 293px auto;
            }
      
            ${vars.breakpoints.xl} {
              grid-template-columns: 430px auto;
            }

            ${vars.breakpoints.xxl} {
              grid-template-columns: 618px auto;
            }

            .hit-content {  
              .title{
                .titleDescSale{
                  display: flex;
                  justify-content: center;
                  ${vars.breakpoints.sm} {
                    justify-content: start;
                  }
                  i{
                    padding-top: 4px;
                    padding-left: 10px;
                  }
                }
                font-size: ${vars.fonts.sizeH7};
                ${vars.breakpoints.xl} {
                  font-size: ${vars.fonts.sizeH9};
                }
                i:before{
                  color: ${vars.colors.saleTagBg};
                }
              }
            }
          }
        }
      }
      &.grid-view { 
        .ais-Hits-list {
          .ais-Hits-item {
            min-height: 572px;
            ${vars.breakpoints.xl} {
              min-height: 620px;
            }
            ${vars.breakpoints.xxl} {
              min-height: 634px;
            }
            .hit-content { 
              flex:1;
              justify-content: start;
              .title {
                .titleDescSale{
                  display: flex;
                  justify-content: center;
                  a{
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                  i{
                    padding-top: 4px;
                    padding-left: 10px;
                  }
                }
                font-size: ${vars.fonts.sizeH6};
                ${vars.breakpoints.xxl} {
                  font-size: ${vars.fonts.sizeH8};
                }
                font-size: ${vars.fonts.sizeH7};
                ${vars.breakpoints.xxl} {
                  font-size: ${vars.fonts.sizeH8};
                }
                .ais-Highlight-nonHighlighted {
                  color: ${vars.colors.grey50};
                }
                i:before {
                  color: ${vars.colors.saleTagBg};
                }
              }
              .inline-container {
                margin: 12px 0;
                ${vars.breakpoints.sm} {
                  margin: 16px 0;
                }
                ${vars.breakpoints.md} {
                  margin: 9px 0;
                  // margin: 45px 0;
                }
                ${vars.breakpoints.xl} {
                  margin: -10px 0 0 0;
                }
                .reserveTodayContainer {
                  width: 100%;
                  > div {
                    margin: 18.5px 0 24px;
                    background: ${vars.colors.whiteWine};
                    border-radius: 4px;
                    padding: 12px;
                    align-items: center;
                  }
                  a {
                    text-decoration: underline;
                    font-family: ${vars.fonts.familySecondary};
                    font-size: ${vars.fonts.sizeSmaller};
                    font-weight: ${vars.fonts.weightMedium};
                    line-height: 19px;
                    ${vars.breakpoints.lg} {
                      font-size: ${vars.fonts.sizeDefault};
                    }
                    ${vars.breakpoints.xl} {
                      font-size: ${vars.fonts.sizeLarge};
                    }
                  }
                }
              }
              .web-description {
                &.case {
                  position: unset;
                  transform: none;
                }
              }
            }
            .price-section-container {
              margin-bottom: 12px;
              text-align: center;
            }
            .presell-section {
              font-family: ${vars.fonts.familyTertiary};
              font-size: ${vars.fonts.sizeH6};
              font-weight: ${vars.fonts.weightBold};
              color: ${vars.colors.grey50};
            }
            .cart-slider button:focus {
              color: ${vars.colors.white} !important;
              background-color: ${vars.colors.btnsPrimaryBg};
              border-color: transparent;
            }
            .price-section {
              margin-bottom: 10px;
              text-align: center;
              padding: 0px;
              line-height: 17.5px;
              .price-section-single-bottle {
                margin: 15px 0px;
                font-size: 16px;
              }
              .sku-price {
                font-family: ${vars.fonts.familyTertiary};
                font-size: ${vars.fonts.sizeH6};
                font-weight: ${vars.fonts.weightBold};
                color: ${vars.colors.grey50};
                line-height: 22.5px;
                .original-price{
                  &.line-through {
                    text-decoration: line-through;
                    font-size: 16px;
                    font-family: ${vars.fonts.familySecondary};
                    font-weight: 400;
                  }
                }
                .sales-price {
                  color: ${vars.colors.textPrimaryBg}
                }
              }
            }
            .savings-sales-price{
              margin-bottom: 10px;
              font-family: ${vars.fonts.familySecondary};
              font-size: ${vars.fonts.sizeSmall};
              font-weight: ${vars.fonts.weightMediumPlus};
              color: ${vars.colors.textPrimaryBg};
              text-transform: uppercase;
            }
            .member-price-section {
              margin-bottom: 10px;
              font-size: ${vars.fonts.sizeSmall};
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightMediumPlus};
              text-transform: uppercase;
              color: ${vars.colors.blue600};
            }
          }
        }
      }
      `,
    },

    Cart: {
      slider: `
      .mantine-Drawer-header {
        padding: 16px 32px 16px 32px;
        border-bottom: 2px solid ${vars.colors.grey20};
      }
      h2 {
        &.mantine-Drawer-title {
          text-transform: uppercase;
          width: 100%;
          font-family: ${vars.fonts.familyPrimary};
          font-size: ${vars.fonts.sizeH10};
          font-weight:${vars.fonts.weightMediumPlus};
          color: ${vars.colors.primary8};
          line-height: 36px;
          margin-bottom: 0;
        }
      }
      .mantine-Drawer-close {
          width: 44px;
          height: 44px;
          gap: 8px;
          svg {
            width: 30px !important;;
            height: 30px !important;;
          }
      }
      .mantine-Drawer-body {
        padding: 16px 32px 16px 32px;
        .image-bg {
          width: 100%;
          border-radius: 4px;
          .image-container {
            height: 236px;
            display: flex;
            justify-content: center;
            .offer {
              .stock {
                position: absolute;
                left: 10px;
                bottom: 4px;
                margin-bottom: 0;
                z-index: 0;
              }
              svg {
                position: absolute;
                left: 10px;
                top: 10px;
              }
            }
            .image {
              padding: 10px 0px;
              img {
                position: relative !important
              }
            }
            .quick-links-container {
              flex-direction: column-reverse;
              gap: 15px;
              bottom: 10px;
            }
            .quick-view-container {
              display: none;
            }
          }
        }
        .image-bg {
          .image-container {
            .image {
              a {
                img {
                  object-fit: contain;
                }
              }
            }
          }
        }
        .hit-content {
          margin-top: 15px;
          .title {
            .titleDescSale{
              display: flex;
              justify-content: center;
              ${vars.breakpoints.sm} {
                justify-content: start;
              }
              i{
                padding-top: 4px;
                padding-left: 10px;
              }
            }
            padding: 15px 0px;
            font-family: ${vars.fonts.familyTertiary};
            font-weight: ${vars.fonts.weightMediumPlus};
            font-size:  ${vars.fonts.sizeH8};
            color: ${vars.colors.primary8};
            ${vars.breakpoints.xs} {
              font-size: ${vars.fonts.sizeH7};
            }
            line-height: 27px;
            text-align: center;
            margin: 0 auto;
            width: fit-content;
            i:before {
              color: ${vars.colors.saleTagBg};
            }
          }
          .rating {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            .star-icons svg {
              margin: 2px;
              width: 16px;
              height: 16px;
            }
            .review-count {
              padding-left: 10px;
              line-height: 18px;
            }
          }
        }
          
        .unlimited-banner {
          margin-top: 24px;
          background: ${(vars.colors.bgColors as unknown as { [key: string]: string }).whiteWine};
          display: flex;
          padding: 16px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;
          align-self: stretch;
          border-radius: 5px;

          .banner-decription {
            font-family: ${vars.fonts.familySecondary};
            font-size: ${vars.fonts.sizeLarge};
            line-height: 140%;
          }

          button {
            min-width: 181px;
            height: 32px;
            border-radius: 4px;
            padding: 7px 16px;
            background: ${vars.colors.unlimitedButtonColor};
            color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            font-family: ${vars.fonts.familySecondary};
            font-size: ${vars.fonts.sizeSmall};
            font-weight: 600;
            text-transform: uppercase;
            letter-spacing: 0.24px;
            line-height: ${vars.fonts.sizeDefault};
            border: none;
          }
        }
    `,
      MiniCart: `
        ${buildThemeWidget.Cart.MiniCart}
        .price-section .strike+.sale-price{
          color: ${vars.colors.primary1}
        }
        .price-section .strike+.member-price {
          color:${vars.colors.blue600};
        }

        .member-savings {
          color: ${vars.colors.blue600};
          font-weight: ${vars.fonts.weightMediumPlus};
        }
      `,
    },
    Product: {
      countryNameGrapeVariety: `
      ${buildThemeWidget.Product.countryNameGrapeVariety}
      .country-grapeVariety {
        .country-title {
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          text-align: left;
        }
      }
      `,

      learnMoreWrapper: `
      .cart-button-wrapper {
        .cart-button {
          button {
            border-radius: 4px;
          }
        }
      }
        .mantine-Overlay-root {
          z-index: 600;
        }
        .mantine-Modal-body {
          height: 100% !important;
          width: 100% !important;
          .modalTitle {
            font-family: ${vars.fonts.familyPrimary};
            font-size: 30px;
            font-weight: 500;
            line-height: 33px;
            text-align: center;
            display: flex;
            margin: auto;
            justify-content: center;
            color: ${vars.colors.white};
            margin: 12px !important;
            text-transform: capitalize;
            @media (max-width: 480px) {
              font-size: 25px;
            }
          }
          .modalTitle2 {
            text-transform: none;
            margin-bottom: 25px !important;
            display: flex;
            margin: auto;
            justify-content: center;
            font-family: ${vars.fonts.familyTertiary};
            font-size: 18px;
            font-weight: 600px;
            line-height: 25.2px;
            text-align: center;
            color: ${vars.colors.white};
            @media (max-width: 480px) {
              font-size: 16px;
            }
          }
          .divider {
            margin-bottom: 36px;
          }
          .description {
            margin-bottom: 36px;
            font-family: ${vars.fonts.familySecondary};
          }
          .incentives {
            margin-bottom: 20px;
            font-size: 14px;
            font-weight: 400px;
            font-family: ${vars.fonts.familySecondary};
          }
        }
        background-color: ${vars.colors.unltPopupBackground};
        .mantine-Modal-inner {
          .mantine-Paper-root {
            max-width: 450px !important;
            @media (max-width: 480px) {
              max-width: 375px !important;
            }
          }
          z-index: 1000;
          .learnMore-header {
            margin-top: 10px;
            color: ${vars.colors.unltPopupColor};
            background-color: ${vars.colors.unltPopupBackground};
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;
            width: 100%;
            .unlimited-img {
              position: absolute;
              left: 50%;
              transform: translateX(-50%);
            }
            .learnMore-closeBtn {
              color: ${vars.colors.unltPopupcloseBtn};
              margin-left: auto;
            }
          }
          .termsAndCondContent {
            height: 200px;
            overflow-y: scroll;
            scrollbar-width: none;
            &::-webkit-scrollbar {
             display: none; 
            }
          }
          button{
            width: 191px;
            height: 44px;
            background-color: ${vars.colors.untlPopupButtonBgColor};
            border-radius: 0px;
            color: ${vars.colors.untlPopupButtonColor};
            display:inline-flex;
            align-items: center;
            justify-content: center;
            font-family: ${vars.fonts.familySecondary};
            .loadIcon {
              display: flex;
              align-items: center;
              justify-content: center;
              .addingText {
                margin-left: 30px;
              }
            }
          }
          .termsAndCond {
            color: ${vars.colors.unltPopupColor};
            max-height: 200px;
            overflow-y: auto;
            margin-top: 10px;
            font-size: 16px;
            font-weight: 500px;
          }
          .termsAndCondContent {
            margin-top: 10px;
            font-size: 0.8rem;
          }

          .mantine-Paper-root {
            background-color: ${vars.colors.unltPopupBackground};
            .mantine-Text-root {
              color: ${vars.colors.unltPopupColor};
            }
          }
        }
`,
      orderform: `
      ${buildThemeWidget.Product.orderform}
      .price-section-container {
        font-size: ${vars.fonts.sizeLarge};
        text-align: left;
        .font-weight-bold {
          font-weight: ${vars.fonts.weightBold};
        }
        
        .case-sku-pricing-panel{
          display: flex;
          align-items: center;
          .strike-case-sku-pricing-panel{
            color: ${vars.colors.textSecondayBg};
            padding-right: 8px;
          }
          .case-sku-sales-price, .case-sku-savings-sales-price, .case-sku-savings-sales-price-isUnlimited{
            font-family: ${vars.fonts.familyTertiary};
            font-size: ${vars.fonts.sizeH10};
            font-weight:${vars.fonts.weightMediumPlus}
          }
          .case-sku-savings-sales-price{
            color:${vars.colors.textPrimaryBg};
          }
        }
        .price-section-case-sku {
          color: ${vars.colors.textSecondayBg};
          margin-top: 20px;
        }
      }
      .top-price-section {
        .price-section {
          padding: 0;
          margin: 0;
            .total-price {
            .single-amount {
              margin-bottom: 16px;
              color: ${vars.colors.primary8};
              font-family: ${vars.fonts.familyTertiary};
              font-weight: ${vars.fonts.weightSmall};
              font-size: ${vars.fonts.sizeH10};
              line-height: 36px;
            }
            .per-bottle-price {
              margin-top: 8px;
              margin-bottom: 12px;
              color: ${vars.colors.textSecondayBg};
              font-family: ${vars.fonts.familySecondary};
              font-weight: ${vars.fonts.weightNormal};
              font-size: ${vars.fonts.sizeLarge};
              line-height: 22.5px;
            }
          }
          .presell-container {
            background: ${vars.colors.whiteWine};
            text-align: left;
            color: ${vars.colors.primary8};
            padding: 12px 16px 1px 12px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
            width: 100%;
            ${vars.breakpoints.sm} {
              width: 90%;
            }
            .presell-header-box {
              display: flex;
              gap: 12px;
              align-items: center;
              i {
                font-weight: bold !important;
              }
              .presell-header {
                font-family: ${vars.fonts.familySecondary};
                font-weight: ${vars.fonts.weightBold};
                font-size: ${vars.fonts.sizeLarge};
                line-height: 22.5px;
              }
            }
            .presell-price-list {
              list-style-type: disc;
              margin: 4px 0;
              padding-left: 50px;
              .presell-price-item {
                font-family: ${vars.fonts.familySecondary};
                font-weight: ${vars.fonts.weightNormal};
                font-size: ${vars.fonts.sizeDefault};
                line-height: 18px;
              }
            }
          }
        }
      }

      .straight-sku-selected-price, .straight-sku-selected-price-unlimited{
        font-family: ${vars.fonts.familyTertiary};
        font-size: ${vars.fonts.sizeH10};
        font-weight:${vars.fonts.weightMediumPlus};
        color: ${vars.colors.primary8};
        margin: 30px 0 6px 0;
      }
      .case-sku-savings-sales-price-isUnlimited{
        color: ${vars.colors.textUnlimitedBg};
      }
      .addToCart-QuantitySelector{
        display: flex;
        ${vars.breakpoints.xs} {
          margin-bottom: ${vars.space.md};
        }
        ${vars.breakpoints.sm} {
          margin-bottom: 0px;
        }
        
        .add-to-cart-btn-container{
          flex-grow: 1;
        }
      }
      .add-to-cart-section{
        flex-grow: 1;
        .customProdQantityField {
          & div:first-child{
            margin-right: 0px;
          }
        }
      }
     
        .customProdQantityField {
          
          .input-group-prepend .btn-minus, .input-group-append .btn-plus{
            width: 32px;
            height: 32px;
            border-radius: 50%;
            border: 1px solid ${vars.colors.textSecondayBg};
            background-color:${vars.colors.white};
            color: ${vars.colors.grey50};
          }
          .quantity{
            color: ${vars.colors.grey50};            
          }
          
        }  
      } 
      

      .price-section, .price-section-member-price {
        color: ${vars.colors.grey50};
        text-align: start;
        width: 100%;
        padding: 18px 16px;
        margin-bottom: ${vars.space.lg};
        border-radius: 4px;
        line-height: 22.5px;
        text-align: justify; 
          padding: 18px 16px;

        .radio-button-straight-sku{
          accent-color: ${vars.colors.radioBtnsPrimaryBg};
          width: ${vars.space.lg};
          height: ${vars.space.lg};
          margin-right: ${vars.space.md};
        }
        .single-bottle-sales-price {
          margin-left: 5px;
          // font-weight: ${vars.fonts.weightBold}
        }
        .single-bottle-savings-sales-price{
          color:${vars.colors.textPrimaryBg};
        }
        .single-bottle-savings-sales-price-isUnlimited{
          color: ${vars.colors.textUnlimitedBg};
        }
        
        .strike-case-sku-pricing-panel, .strike-straight-sku-pricing-panel{
          color: ${vars.colors.textSecondayBg};
          padding-right: ${vars.space.sm};
          padding-left: ${vars.space.sm};
        }
      }
      .price-section-member-price{
        margin-bottom:${vars.space.sm};
      }
      .member-price-section-case-sku, .member-price-section-straight-sku{
        display: block;
        font-size: ${vars.fonts.sizeSmall};
        color: ${vars.colors.textUnlimitedBg};
        line-height: ${vars.space.lg};
        font-weight: ${vars.fonts.weightMediumPlus};
        ${vars.breakpoints.md} {
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
        }
        span:nth-child(2){
          display: block; 
          ${vars.breakpoints.md} {
            display: inline;
          }
        }
      }

      .member-price-section-case-sku{
        margin-top: 20px;      
      }
      .member-price-section-straight-sku{
        margin-top: ${vars.space.sm}; 
        margin-bottom: ${vars.space.lg}; 
      }
      .member-price-when-you-mix {
        font-weight: 400;
        padding-left: 3px;
        display: block;
        ${vars.breakpoints.md} {
          display: inline;
        }
      }
      .learn-more-isUnlimited{
        font-weight: 400;
        font-size: 14px;
        .learn-more-isUnlimited-link {
          font-family: ${vars.fonts.familySecondary}!important;
        }
      } 
      `,
      wrapper: `
      ${buildThemeWidget.Product.wrapper}    
      .about-the-wine-container {
        .wine-details-container {
          .second-row {
            .quote {
              background-color: ${vars.colors.cardBackground}!important;
            }
          }
        }
        .auc-Recommend{
          .product-item-container{
            .product-price{
              color:${vars.colors.black};
            }
          }
        }
      } 
      .mixed{
        @media print {
              display: none !important;
            }
      } 
      .product-layout {
        .layout-name {
          .main-title {
            @media print {
              margin-bottom : 0px;
            }
            i {
              color: ${vars.colors.saleTagBg};
            }  
          }
        }
        .product-image-container {
          .image-container {
            .product-info-container {
              .pill {
                border-radius: 30px;
              }
            }
          }
         @media print {
              display: none;
            } 
        }
        .us-webHeadline {
          font-family: ${vars.fonts.familySecondary};
          font-weight: 400;
          margin-top: 5px;
          font-size: 16px;
        }
        .add-on-us-container {
          padding: 20px 20px 0px;
          display: flex;
          gap: 8px;
          flex-direction: column;
          text-align: center;
          align-items: center;
            .price-quantity {
              font-size: 16px;
                .strong-text {
                  font-weight: 700;  
                }
            }
            .price-promo-savings, .price-cart-text {
              font-size: 14px;
                .strike-price {
                  text-decoration: line-through;
                }
                .promo-price {
                  font-size: 16px;
                  font-weight: 700;
                  color: ${vars.colors.textPrimaryBg}
                }
                .savings-price {
                  font-weight: 600;
                }
            }
        }
        .out-of-stock {
          .sold-out-text {
            background: #FFCFD5;
            border: none !important;
          }
          .clickToViewSimilarWines {
            text-align: center;
            background-color: transparent;
            width: 100%;
            text-align: left;
          }
        }
        .top-price-section {
          .presell {
            font-size: 14px;
            line-height: 18.2px;
            font-weight: ${vars.fonts.weightSmall};
            font-family: ${vars.fonts.familySecondary};
            .sale-price {           
              .price {
                font-size: 22px;
                line-height: 26.4px;
                font-weight: ${vars.fonts.weightMediumPlus};
              }
            }
            .total-price {
              font-size: 22px;
              line-height: 26.4px;
              font-weight: ${vars.fonts.weightMediumPlus};
              font-family: ${vars.fonts.familyTertiary};
            }
          }
        }
      }
      .case-content-main-container{
        @media print {
          margin: 15px -5px 0 !important;
          .slick-slider {
            .slick-arrow {
              display: none !important;
            }
            .slick-list {
              margin: 0 !important;
              .slick-track {
                transform: none !important;
                width: auto !important;
                .slick-slide {
                  display: none !important;
                  width: auto !important;
                  text-align: center;
                  &.slick-current {
                    display: block !important;
                    float: none;
                    width: 680px !important;
                  }
                  .layout {
                    margin-top: 0px;
                    .case-compo-heart-icon, .quantity, .redirect-link-container {
                      display: none !important; 
                    }
                  }
                }
              }
            }
          }
          .sticky-cart-btn {
            display: none !important; 
          }
          .accordion-mobile-wine-details {
            .mantine-Accordion-chevron {
              display: none !important; 
            }
            .mantine-Accordion-label {
              padding-bottom: 0;
            }
          }
        } 
        .case-content-container {
          .mantine-Tabs-tabsList .mantine-Tabs-tabIcon img {
            height: 180px;
          }
          .wineDetailsSection {
            @media print {
              padding: 0 !important;
                .main-title {
                  font-size: 26px;
                }
            } 
            .header-container {
              .price {
                @media print {
                  display: none;
                } 
              }
            }
          }
        }
        .sub-title, .mantine-Tabs-tabsList {
          @media print {
            display: none;
          } 
        }
      } 
      .case-content-details-section { 
        @media print {
          .sub-title-with-border {
            font-size: 18px !important;
            margin-bottom: 5px !important;
            padding-bottom: 3px !important;
          }
          .item-title {
            font-size: 16px !important;
          }
          .item-sub-title {
            font-size: 14px !important;
          }
          .award-main-container {
            display: none;
          } 
        }
        .case-image {
          img {
            height: 380px;
            @media print {
              max-height: 350px !important;
            }
          }
        }
        .share-print{
          width:250%;
        }
      }    
      .wine-details-container {
        .award-main-container {
          @media print {
            display: none;
          } 
        }
      }
      .main-content-container {
        .about-the-wine-container {
          .fixed-bottom {
            .cart-button button {
              width: 100%;
            }
            position: fixed;
            border: none;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #117b53;
            padding: 0px;
            box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
            text-align: center;
            z-index: 500;
          }
          .wine-details-container {
            @media print {
              .about-wine .show-in-print-view-only p {
                margin-bottom: 6px;
              }
              .first-row .column-align .sub-title-with-border {
                margin-bottom: 5px;
                padding-bottom: 3px;
              }
              .second-row {
                display: none;
              }
            } 
          }
        }
        .container {
          .fixed-bottom {
            .cart-button button {
              width: 100%;
              .loadIcon {
                display: flex;
                align-items: center;
                justify-content: center;
                .addingText {
                  margin-left: 30px;
                }
              }
            }
            position: fixed;
            border: none;
            bottom: 0;
            left: 0;
            width: 100%;
            background-color: #117b53;
            padding: 0px;
            box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1);
            text-align: center;
            z-index: 500;
          }
        }
      } 
      `,
      presell: `
      ${buildThemeWidget.Product.presell}
        &.presell-cta {
          font-family: ${vars.fonts.familySecondary};
          font-weight: ${vars.fonts.weightMedium};
          font-size: ${vars.fonts.sizeLarge};
          line-height: 19px;
          background: ${vars.colors.whiteWine};
          padding: 0 12px 16px 40px;
          border: none;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 4px;
          margin-bottom: 0;
          
          ${vars.breakpoints.xs} {
            width: 100%;
          }
          ${vars.breakpoints.sm} {
            width: 90%;
          }
          ${vars.breakpoints.md} {
            width: 90%;
          }
          ${vars.breakpoints.lg} {
            width: 90%;
          }
    
          ${vars.breakpoints.xl} {
            width: 90%;
          }
          ${vars.breakpoints.xxl} {
            width: 90%;
          }
          a {
            text-decoration: underline;
          }
        }
        .new-presell-grid-container {
          display: flex;
          align-items: center;
          gap: 8px;
          span {
            font-family: ${vars.fonts.familySecondary};
            font-size: ${vars.fonts.sizeSmaller};
            font-weight: ${vars.fonts.weightBold};
            line-height: 22.5px;
            ${vars.breakpoints.lg} {
              font-size: ${vars.fonts.sizeDefault};
            }
            ${vars.breakpoints.xl} {
              font-size: ${vars.fonts.sizeLarge};
            }
          }
        }
      `,
    },
  });
};

const createTheme: ThemeCreateFunction = () =>
  buildTheme(buildThemeVarsUsLW, buildThemeMixinsUsLW, buildThemeWidgetStylesUsLW);

export default createTheme;
