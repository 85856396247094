import { atom, selector, useRecoilState, useSetRecoilState } from 'recoil';
import { initCommerceApiWithGlobals } from 'common-api';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import { UserSubscriptions, ErrorResponse } from 'tsconfig/types.d';
import { isBrowser } from '../helpers/utils';
import { logger } from 'common-ui';

//Atom
//----------------------------------------------------------------------
export const subscriptionsAtom = atom<UserSubscriptions>({
  key: 'subscriptionsAtom', // It saves the user-me subscription data
  default: undefined,
  effects: [effectConsoleLogAtomUpdates],
});

export const errorState = atom<any>({
  key: 'errorState',
  default: undefined,
});

//Selectors
//----------------------------------------------------------------------
export const selectSubscriptionDetails = selector({
  key: 'selectSubscriptionDetails', // To select the me-user subscription selector
  get: ({ get }) => {
    const subscriptionData = get(subscriptionsAtom);
    if (typeof subscriptionData !== 'undefined' && subscriptionData) {
      return {
        subscriptionData,
        subscriptionStatus: 'success',
      };
    } else {
      const error = get(errorState);
      return {
        subscriptionStatus: 'error',
        message: error,
      };
    }
  },
});

export const useInitSubState = () => {
  const [subscriptionData, setSubscriptionData] = useRecoilState<UserSubscriptions>(subscriptionsAtom);
  const setError = useSetRecoilState(errorState);
  const initSubState = async () => {
    if (isBrowser()) {
      if (subscriptionData) {
        logger?.log('skipping the fetch call if already contains');
        return;
      }
      try {
        const commerceApi = initCommerceApiWithGlobals();
        const newCartData: UserSubscriptions = await commerceApi.Subscription.get();
        setSubscriptionData(newCartData);
      } catch (err) {
        const apiError = err as ErrorResponse;
        setError(apiError?.response?.data?.message || apiError?.message);
        logger?.error('API ERROR', err);
      }
    } else {
      logger?.log('useInitSubState skipping fetch as not in browser');
    }
  };
  return initSubState;
};
