import { css, useTheme } from '@emotion/react';
import { globals } from 'common-ui';
interface FlavourProfileProps {
  product: {
    shortTastingNotesTaste: string;
    shortTastingNotesAroma: string;
    allergens: string[];
  };
}

export const FlavourProfile = (props: FlavourProfileProps) => {
  const { product } = props;

  const shortTastingNotesTaste = product.shortTastingNotesTaste;
  const shortTastingNotesAroma = product.shortTastingNotesAroma;
  const allergens = product.allergens;

  const theme = useTheme();

  const wrapper = css`
    ${theme.widgets.Product?.flavourProfile}
  `;

  const formatAllergensArray = allergens?.map((item, index) => {
    // Capitalize the first letter
    const capitalizedItem = item.charAt(0).toUpperCase() + item.slice(1).toLowerCase();
    // Add comma except for the last item
    return index < allergens?.length - 1 ? capitalizedItem + ', ' : capitalizedItem;
  });

  const renderFoodPairing = () => {
    return (
      <>
        {shortTastingNotesTaste && (
          <div className="item">
            <div className="item-title">Wine Flavor Profile</div>
            <div className="item-sub-title">{shortTastingNotesTaste}</div>
          </div>
        )}
        {shortTastingNotesAroma && (
          <div className="item">
            <div className="item-title">Wine Food Pairings</div>
            <div className="item-sub-title">{shortTastingNotesAroma}</div>
          </div>
        )}
      </>
    );
  };

  const renderTastingNotes = () => {
    return (
      <>
        {shortTastingNotesTaste && (
          <div className="item">
            <div className="item-title">Tasting notes</div>
            <div className="item-sub-title">{shortTastingNotesTaste}</div>
          </div>
        )}
        {shortTastingNotesAroma && (
          <div className="item">
            <div className="item-title">Aroma</div>
            <div className="item-sub-title">{shortTastingNotesAroma}</div>
          </div>
        )}
        {globals.country === 'uk' && allergens && allergens.length > 0 && (
          <div className="item">
            <div className="item-title">Allergy information</div>
            <div className="item-sub-title">Contains {formatAllergensArray}</div>
          </div>
        )}
      </>
    );
  };

  return <div css={wrapper}>{globals.country === 'us' ? renderFoodPairing() : renderTastingNotes()}</div>;
};
