import React, { useMemo } from 'react';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { BannerProps } from 'tsconfig/types';
import { Button, inspectorMode } from 'common-ui';
import { fontCorrectionInRichText } from '../../utils/fontCorrectionInRichText';

// Memoized component to prevent unnecessary re-renders
const BannerWithContentAndCTA = React.memo(({ contentItem }: BannerProps) => {
  const { CTALink, contentBackgroundColour, bannerContent, alignment } = contentItem.fields || {};
  const theme = useTheme();

  // Memoizing the wrapper styles to avoid recreating styles on each render
  const wrapper = useMemo(
    () => css`
      ${theme.widgets.CmsBanner?.wrapper};
      background-color: ${contentBackgroundColour || 'transparent'};
      .banner-info {
        text-align: ${alignment?.toLowerCase() || 'left'};
        .banner-button {
          justify-content: ${alignment?.toLowerCase() || 'left'};
        }
      }
    `,
    [contentBackgroundColour, alignment, theme.widgets.CmsBanner?.wrapper],
  );

  const widgetTheme = theme.widgets.CmsBanner || {};
  const Section = useMemo(
    () => styled.section`
      ${widgetTheme.wrapper};
    `,
    [widgetTheme.wrapper],
  );

  // Memoize the button classes based on conditions to avoid recalculating on each render
  const getButtonClass = (variant: string) => {
    switch (variant) {
      case 'Secondary button':
        return 'darkColor';
      case 'Secondary-inverted button':
        return 'lightColor';
      default:
        return '';
    }
  };

  return (
    <div css={wrapper} className="bannerWithContentandCTA">
      <div className="banner-info">
        <Section className="banner-content">
          {bannerContent && /<[a-z][\s\S]*>/i.test(bannerContent) && (
            <div
              dangerouslySetInnerHTML={{ __html: fontCorrectionInRichText(bannerContent, theme.fonts) }}
              {...inspectorMode(contentItem?.sys?.id, 'bannerContent')}
            />
          )}
        </Section>

        {CTALink?.length > 0 && (
          <div className="banner-button">
            {CTALink.map((value: any, index: number) => (
              <a href={value?.fields?.url} key={index}>
                <Button
                  children={value?.fields?.title}
                  className={getButtonClass(value?.fields?.variant)}
                  kind="tertiary"
                  {...inspectorMode(value?.sys?.id, 'title')}
                />
              </a>
            ))}
          </div>
        )}
      </div>
    </div>
  );
});

export default BannerWithContentAndCTA;
