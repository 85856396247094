interface SkeletonProps {
  height?: string | number;
  width?: string | number;
  mt?: string | number;
  mb?: string | number;
  radius?: 'sm' | 'md';
  circle?: boolean;
}

export const Skeleton: React.FC<SkeletonProps> = ({
  height = 16,
  width = '100%',
  mt = 0,
  mb = 0,
  radius = 'sm',
  circle = false,
}) => {
  const styles = {
    height: typeof height === 'number' ? `${height}px` : height,
    width: typeof width === 'number' ? `${width}px` : width,
    marginTop: typeof mt === 'number' ? `${mt}px` : mt,
    marginBottom: typeof mb === 'number' ? `${mb}px` : mb,
    borderRadius: circle ? '50%' : radius === 'sm' ? '4px' : '8px',
    backgroundColor: '#e0e0e0',
    animation: 'pulse 1.5s infinite',
  };

  return <div data-testid="skeleton" style={styles}></div>;
};
