import React, { memo, useEffect, useState } from 'react';
import { RichTextEntryType } from 'tsconfig/types.d';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';
import { inspectorMode } from 'common-ui';
import { fontCorrectionInRichText } from '../../../utils/fontCorrectionInRichText';

export type CmsRichTextProps = {
  contentItem: RichTextEntryType;
};

export const CmsRichText = memo((props: CmsRichTextProps) => {
  const { contentItem } = props;
  const theme = useTheme();
  const widgetTheme = theme.widgets.CmsRichText || {};
  const Wrapper = styled.section`
    ${contentItem?.fields?.backgroundColour && ` background-color: ${contentItem?.fields?.backgroundColour}`}
    ${widgetTheme.wrapper}
  `;

  const contentToSanitize =
    typeof contentItem?.fields?.content === 'string' &&
    (contentItem?.fields?.content as string).replace(/<[^>]*>/g, '').trim()
      ? contentItem?.fields?.content
      : '';

  // Code to add padding for element with Backgroud color from Rich-text
  const addBackgroundClass = (element: HTMLElement) => {
    const inlineStyle = element.getAttribute('style');

    if (inlineStyle && inlineStyle.includes('background')) {
      element.classList.add('richtext-background');
    }
  };

  const traverseDOM = (node: Node) => {
    if (node?.nodeType === 1) {
      addBackgroundClass(node as HTMLElement);

      for (let i = 0; i < node?.childNodes?.length; i++) {
        traverseDOM(node?.childNodes[i]);
      }
    }
  };

  const parseHtmlContent = (htmlContent: string) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html');
    const body = doc.body;
    traverseDOM(body);

    return body.innerHTML;
  };

  const [modifiedContent, setModifiedContent] = useState(contentToSanitize);

  useEffect(() => {
    if (typeof contentToSanitize === 'string') {
      const updatedContent = parseHtmlContent(fontCorrectionInRichText(contentToSanitize, theme.fonts));
      setModifiedContent(updatedContent);
    }
  }, [contentToSanitize]);

  //TODO: to support asset links in rich text
  //see https://www.contentful.com/developers/docs/concepts/rich-text/
  return (
    <Wrapper
      data-test="contentful-textbox"
      className={`richtext-wrapper ${contentItem?.fields?.backgroundColour ? 'richtext-has-bg' : ''}`}
    >
      {typeof contentItem?.fields?.content === 'string' && (
        <div
          dangerouslySetInnerHTML={{ __html: modifiedContent }}
          {...inspectorMode(contentItem?.sys?.id, 'content')}
        />
      )}
    </Wrapper>
  );
});
