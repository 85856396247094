//TODO: move this somewhere else
export const isBrowser = () => typeof window === 'object';

export const formatDateToDDMMMYYFromISO = (dateString: string): string => {
  return new Date(dateString).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: '2-digit',
  });
};

export function generateRandomClientId(): string {
  let sessionUID = sessionStorage.getItem('sessionUID');
  if (!sessionUID) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const length = 20;
    sessionUID = Array.from({ length }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join(
      '',
    );
    sessionStorage.setItem('sessionUID', sessionUID);
  }
  return sessionUID;
}
