import React, { memo } from 'react';
import { css, useTheme } from '@emotion/react';
import { Icon, DrawerMenu, globals, MenuDropdown } from 'common-ui';
import { useRecoilValue, selectUserIsLoggedIn, selectUserDetails, logout } from 'common-state';
import { HeaderNavigationEntryType, LinkEntryType } from 'tsconfig/types.d';
import { Menu, NavLink } from '@mantine/core';
import { useScreenLayout } from '../../../../hooks/useScreenLayout';
import { isBrowser } from '../../../../utils/isBrowser';
import { TypeBasedConditionalContent } from '../../../contentful/CmsConditionalContainer/TypeBasedConditionalContent';
import { handleDLClickEvent } from '../../../../data-layer/handleDLClickEvent';

export type AccountNavBarProps = {
  cmsAccountNav?: HeaderNavigationEntryType;
  enableCurityLogin?: boolean;
};

export const HeaderSignIn = memo((props: AccountNavBarProps) => {
  const { cmsAccountNav, enableCurityLogin } = props;
  const isLoggedIn = useRecoilValue(selectUserIsLoggedIn);
  const userDetails = useRecoilValue(selectUserDetails);
  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.GlobalHeader?.headerSignIn}
  `;
  const truncatedName = userDetails?.firstName?.charAt(0) + (userDetails?.lastName?.charAt(0) || '');
  const { screen } = useScreenLayout();

  const locationHref = isBrowser() ? window.location.href : '/';

  const menuLabel = () => {
    return (
      <div className="user-logged-in-label">
        <span className="sign-in-label">{truncatedName}</span>
        <span className="sign-in-name">Hi, {truncatedName}</span>
      </div>
    );
  };

  const menuList = () => {
    if (!cmsAccountNav?.fields) {
      return null;
    }

    return (
      <div>
        {(cmsAccountNav?.fields.navigations as LinkEntryType[])?.map(navigation => (
          <TypeBasedConditionalContent<LinkEntryType>
            content={navigation}
            contentTypes={['link']}
            key={navigation?.fields?.entryTitle}
          >
            <Menu.Item
              component={'a'}
              key={navigation?.fields?.url}
              href={navigation?.fields?.url}
              data-event="accountNavClick"
              data-section={`account:${navigation?.fields?.title}`}
              data-action="navigation"
              data-name="account navigation"
              data-text={navigation?.fields?.title}
            >
              {navigation?.fields?.title}
            </Menu.Item>
          </TypeBasedConditionalContent>
        ))}
        <Menu.Divider />
        {userDetails.loginType === 'hard_logged' ? (
          <Menu.Item
            component={'a'}
            onClick={() => {
              handleDLClickEvent('logout', 'logout');
              logout('soft_logged');
            }}
            data-event="accountNavClick"
            data-section="account:Sign out"
            data-action="navigation"
            data-name="account navigation"
            data-text="Sign out"
            className="signout-button"
          >
            Sign out
          </Menu.Item>
        ) : (
          <Menu.Item
            component={'a'}
            onClick={() => {
              handleDLClickEvent('not you? sign out', 'notYou');
              logout('hard_logged');
            }}
            data-event="accountNavClick"
            data-section="account:Not you? Sign out"
            data-action="navigation"
            data-name="account navigation"
            data-text="Not you? Sign out"
            className="signout-button"
          >
            Not you? Sign out
          </Menu.Item>
        )}
      </div>
    );
  };

  const menuListDrawer = () => {
    if (!cmsAccountNav?.fields) {
      return null;
    }

    return (
      <>
        <p className="drawer-menu-heading">Hi, {userDetails?.firstName}</p>
        <div>
          {(cmsAccountNav?.fields.navigations as LinkEntryType[])?.map(navigation => (
            <TypeBasedConditionalContent<LinkEntryType>
              content={navigation}
              contentTypes={['link']}
              key={navigation?.fields?.title}
            >
              <NavLink
                component={'a'}
                label={navigation?.fields?.title}
                href={navigation?.fields?.url}
                key={navigation?.fields?.url}
                data-event="accountNavClick"
                data-section={`account:${navigation?.fields?.title}`}
                data-action="navigation"
                data-name="account navigation"
                data-text={navigation?.fields?.title}
              />
            </TypeBasedConditionalContent>
          ))}
          {userDetails.loginType === 'hard_logged' ? (
            <NavLink
              component={'a'}
              label="Sign out"
              onClick={() => {
                handleDLClickEvent('logout', 'logout');
                logout('soft_logged');
              }}
              data-event="accountNavClick"
              data-section="account:Sign out"
              data-action="navigation"
              data-name="account navigation"
              data-text="Sign out"
              className="signout-button highlighted-link"
            />
          ) : (
            <NavLink
              component={'a'}
              label="Not you? Sign out"
              onClick={() => {
                handleDLClickEvent('not you? sign out', 'notYou');
                logout('hard_logged');
              }}
              data-event="accountNavClick"
              data-section="account:Not you? Sign out"
              data-action="navigation"
              data-name="account navigation"
              data-text="Not you? Sign out"
              className="signout-button highlighted-link"
            />
          )}
        </div>
      </>
    );
  };

  const showDropDownView = ['lg', 'xl', 'xxl'].includes(screen);

  return (
    <div data-test="widget-HeaderSignIn" css={wrapper}>
      {isLoggedIn ? (
        <div id="user-logged-in">
          {showDropDownView ? (
            <MenuDropdown
              target={menuLabel()}
              targetContent={menuList()}
              dropdownClassName="account-navbar"
              hover={true}
            />
          ) : (
            <DrawerMenu position="left" target={menuLabel()} className="account-navbar">
              {menuListDrawer()}
            </DrawerMenu>
          )}
        </div>
      ) : (
        <div id="user-not-logged-in">
          <a
            href={
              globals.brandId === 'vws'
                ? '/jsp/templates/page_elements/au/vws/velocityRedirectPage.jsp'
                : enableCurityLogin
                  ? `/jsp/curity/signin.jsp?redirectionURL=${locationHref}`
                  : '/jsp/registration/common/account_login.jsp'
            }
            className="sign-in-link"
            onClick={() => {
              handleDLClickEvent('top navigation click', 'loginButton');
            }}
          >
            <Icon kind="user" size="m" />
            {globals?.country !== 'us' && <span>Sign in</span>}
          </a>
        </div>
      )}
    </div>
  );
});
