import algoliasearch from 'algoliasearch/lite';
import { globals } from '../../utils/globals';

/**
 * Returns the Algolia search server configuration.
 *
 * @returns {AlgoliaSearchClient} The Algolia search client instance.
 */
export const searchServerConfig = () => {
  const appId = `${process.env.NEXT_PUBLIC_INSTANT_SEARCH_APP_ID}`;
  const searchKey = `${process.env.NEXT_PUBLIC_INSTANT_SEARCH_SEARCH_KEY}`;
  return algoliasearch(appId, searchKey, {
    headers: {
      Referer: `${process.env.NEXT_PUBLIC_HOST}`,
    },
  });
};

/**
 * Returns the configuration object for the Algolia search client.
 * @returns The configuration object for the Algolia search client.
 */
export const searchClientConfig = () => {
  const appId = `${globals.algoliaAppId}`;
  const searchKey = `${globals.algoliaSearchKey}`;
  return algoliasearch(appId, searchKey, {
    // Used for SSR Call only for Algolia
    headers: {
      Referer: `${globals.host}`,
    },
  });
};

export const algoliaClient = algoliasearch(`${globals.algoliaAppId}`, `${globals.algoliaSearchKey}`);
