/* eslint-disable no-console */
// Simple logger to output server log messages in a structure consistent with other Netlify log messages

const LogLevel: Record<string, number> = {
  ALL: 1,
  LOG: 2,
  INFO: 3,
  WARN: 4,
  ERROR: 5,
  NONE: 6,
} as const;

const LogOutputFormat: Record<string, number> = {
  SIMPLE: 1,
  JSON: 2,
} as const;

function createLogMethod(methodName: string, logLevel: number) {
  return function (this: any, ...args: any[]) {
    if (logLevel >= this.currentLogLevel) {
      switch (this.logFormat) {
        case LogOutputFormat.SIMPLE:
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          console[methodName](methodName.toUpperCase(), ...args);
          break;

        case LogOutputFormat.JSON:
        default: {
          const message = {
            log_type: 'console',
            level: logLevel,
            log_message: args.join(' '),
            timestamp: new Date().toISOString(),
          };
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          console[methodName](message);
          break;
        }
      }
    }
  };
}

const createLogger = () => {
  const defaultLogLevel = (process.env.NEXT_PUBLIC_LOGGER_LOG_LEVEL || '').toUpperCase();
  const defaultFormat = (process.env.NEXT_PUBLIC_LOGGER_FORMAT || '').toUpperCase();

  return {
    currentLogLevel: LogLevel[defaultLogLevel] || LogLevel.ALL,
    logFormat: LogOutputFormat[defaultFormat] || LogOutputFormat.SIMPLE,

    log: createLogMethod('log', LogLevel.LOG),
    info: createLogMethod('info', LogLevel.INFO),
    warn: createLogMethod('warn', LogLevel.WARN),
    error: createLogMethod('error', LogLevel.ERROR),
  };
};

export const logger = createLogger();
