/*
Wrapper for handling links to Netlify/NextJs or ATG pages. This mostly effects dynamically generated that 
come from Contentful content.

ATG urls = we need to render ATG urls as vanilla <a> tags because same domain links to ATG pages were
initially being mis handled by the NextJs routing. This caused pre cache calls for intermediary json files to be
sent to ATG which filled the logs with errors. Hence links to ATG from Netlify need to be rendered as <a> tags

NextJs urls = for NextJs urls we DONT want to use <a> tags as that stops the NextJs routing from working correctly. In
that case we want to use the NextJs <Link> 

This component wont be necessary once we finish migrated away from ATG
*/
import React, { memo } from 'react';
import NextLink, { LinkProps as NextLinkProps } from 'next/link';
import { globals } from 'common-ui';
import { testUrlMatchesPatternList } from './urlUtils';

export type LinkProps = Record<string, any>;

export const Link = memo((props: LinkProps) => {
  const { href, children } = props;
  const isNetlifyUrl = testUrlMatchesPatternList(href, globals.netlifyRoutes as string);

  if (isNetlifyUrl) {
    return <NextLink {...(props as NextLinkProps)}>{children}</NextLink>;
  } else {
    //It can be hard to determine if a link is for ATG or Next when inspecting in browser so we add
    //a data-atg attribbute
    return (
      <a data-atg="atg-link" {...props}>
        {children}
      </a>
    );
  }
});
