import React, { memo } from 'react';
import { ProductCardEntryType, ProductProps } from 'tsconfig/types';
import { ProductCard, ProductCardPriceTypes } from '../../ProductCard/ProductCard';
import useProductData from '../../../hooks/useProductData';
import get from 'lodash/get';
export interface CmsProductCardProps {
  contentItem: ProductCardEntryType;
}

export const CmsProductCard = memo(({ contentItem }: CmsProductCardProps) => {
  const {
    productCode,
    productCardBackgroundImage,
    productCardButton,
    productCardVariant,
    productCardBackgroundColor,
    productCardTitle,
    productCardSubTitle,
    productCardDescription,
    productImageOverride,
    productImageSecondary,
    productLink,
    hidePriceOnMultiView
  } = contentItem.fields || {};
  const { product: singleProductData, loading } = useProductData(productCode);

  let productData = singleProductData;
  const multiViewProducts: ProductProps[] = [];
  const multiViewPrices: ProductCardPriceTypes[] = [];
  let multiViewPriceDiff = false;

  productLink?.forEach(link => {
    const code = link?.fields?.productCode;
    if (!code) return;
    const { product } = useProductData(code);
    if (product) {
      multiViewProducts.push(product);
    }
  });

  if (productCardVariant === 'Large Product Multi View') {
    productData = multiViewProducts[0]
  }

  const vppPrice = get(productData, 'skus[0].vppPrice', null);
  const salePrice = get(productData, 'skus[0].salePrice', null);
  const buyersRRP = get(productData, 'skus[0].buyersRRP', null);
  const memberPrice = get(productData, 'skus[0].memberPrice', null);
  const numberOfBottles = get(productData, 'skus[0].numberOfBottles', null);

  multiViewProducts.map(product => {
    const multiViewVppPrice = get(product, 'skus[0].vppPrice', null);
    const multiViewSalePrice = get(product, 'skus[0].salePrice', null);
    const multiViewBuyersRRP = get(product, 'skus[0].buyersRRP', null);
    const multiViewMemberPrice = get(product, 'skus[0].memberPrice', null);
    const multiViewnumberOfBottles = get(product, 'skus[0].numberOfBottles', null);

    multiViewPrices.push({
      productPrice: multiViewVppPrice ? multiViewVppPrice : multiViewSalePrice,
      productPriceOriginal: multiViewBuyersRRP ? multiViewBuyersRRP : 0,
      productMemberPrice: multiViewMemberPrice,
      productNumberOfBottles: multiViewnumberOfBottles,
    });
  });

  if (multiViewPrices && multiViewPrices[0]?.productPrice !== multiViewPrices[1]?.productPrice) {
    multiViewPriceDiff = true;
  }

  return (
    <ProductCard
      productName={productData?.name || ''}
      productRating={productData?.averageOverallRating}
      productReviewCount={productData?.totalReviewCount}
      productPrice={vppPrice ? vppPrice : salePrice}
      productPriceOriginal={buyersRRP ? buyersRRP : 0}
      productPricePrefix={vppPrice ? 'from ' : ''}
      productMemberPrice={memberPrice}
      productImage={productData?.smallImage || productData?.largeImage}
      productVintage={productData?.vintage || ''}
      productCardBackgroundImage={productCardBackgroundImage}
      productCardBackgroundColor={productCardBackgroundColor}
      productCardButton={productCardButton}
      productCode={(productCardVariant === 'Large Product Multi View' && productLink && productLink?.length > 0) ? (productLink[0]?.fields?.productCode || '') : productCode}
      productCardVariant={productCardVariant}
      productType={productData?.mixed ? 'Case' : productData?.productType ?? ''}
      productNumberOfBottles={numberOfBottles}
      productCardTitle={productCardTitle ? productCardTitle : `${productData?.name} ${productData?.vintage}`}
      productCardSubTitle={productCardSubTitle}
      productDescription={productData?.description}
      productCardDescription={productCardDescription}
      productImageOverride={productImageOverride}
      productImageSecondary={productImageSecondary}
      productLink={productLink}
      multiViewPrices={multiViewPrices}
      multiViewPriceDiff={multiViewPriceDiff}
      hidePriceOnMultiView={hidePriceOnMultiView}
      isLoading={loading}
    />
  );
});
