//Note: types need to be fixed

import { logger } from 'common-ui';
import {
  ContentfulClientApi,
  createClient,
  CreateClientParams,
  EntriesQueries,
  EntryCollection,
  EntrySkeletonType,
  FixedQueryOptions,
} from 'contentful';
import isEmpty from 'lodash/isEmpty';
import { ContentPageContentType, ContentPageEntryType, SiteContentType, SiteEntryType } from 'tsconfig/types';

type Clients = {
  [key: string]: ContentfulClientApi<undefined>;
};

type ContentfulClientConfig = {
  contentfulOrchestration: string;
} & CreateClientParams;

type ContentfulOrchestrations = {
  [key: string]: string;
};

export class ContentfulClient {
  private _clients: Clients = {};
  private DEFAULT_CONTENT_DEPTH: FixedQueryOptions['include'] = 10;
  private UNRESOLVED_LINK = {};
  private contentfulOrchestrations: ContentfulOrchestrations = {};

  constructor(config: ContentfulClientConfig) {
    const primary = createClient({
      space: config.space,
      environment: config.environment,
      accessToken: config.accessToken,
      host: config.host,
    });
    this._clients['primary'] = primary;
    this.createOrchestrationClients(config);
  }

  /**
   * clients
   *
   * @return  {[Clients]}  [return contentful clients]
   */
  public get clients() {
    return this._clients;
  }

  /**
   * primaryClient
   *
   * @return  {[ContentfulClientApi]}  [return primary contentful client]
   */
  public get primaryClient() {
    return this._clients.primary;
  }

  /**
   * createOrchestrationClients
   * create contentful clients for orchestration space
   *
   * @param   {ContentfulClientConfig}  config  [ContentfulClientConfig]
   *
   * @return  {[void]}
   */
  private createOrchestrationClients(config: ContentfulClientConfig) {
    if (!config.contentfulOrchestration) {
      return;
    }
    const configs = config.contentfulOrchestration.split(',');
    // eslint-disable-next-line @typescript-eslint/no-shadow
    configs.reduce((o, config: string) => {
      const [spaceId, token] = config.split(':');
      return Object.assign(o, { [spaceId]: token });
    }, this.contentfulOrchestrations);
  }

  /**
   * [getClient description]
   *
   * @param   {string}  spaceId      [spaceId description]
   * @param   {string}  environment  [environment description]
   *
   * @return  {[ContentfulClientConfig]}  [return contentful client for the space]
   */
  private getClient(spaceId: string, environment: string) {
    let client = this._clients[spaceId + environment];
    if (!client) {
      const token = this.contentfulOrchestrations[spaceId];
      if (!token) {
        return null;
      }
      client = createClient({
        space: spaceId,
        environment: environment,
        accessToken: token,
      });
      this._clients[spaceId + environment] = client;
    }
    return client;
  }

  /**
   * isLink Function
   * Checks if the object has sys.type "Link"
   * @param object
   */
  private isLink = (object: any) => object && object.sys && object.sys.type === 'Link';

  /**
   * isResourceLink Function
   * Checks if the object has sys.type "ResourceLink"
   * @param object
   */
  private isResourceLink = (object: any) => object && object.sys && object.sys.type === 'ResourceLink';

  /**
   * [getIdsFromUrn Derive spaceId, environmentId, entryId from urn key]
   *
   * @param   {string}  urn  [urn description]
   *
   * @return  {[type]}       [return description]
   */
  private getIdsFromUrn(urn: string) {
    const regExp = /.*:spaces\/([^/]+)(?:\/environments\/([^/]+))?\/entries\/([^/]+)$/;

    if (!regExp.test(urn)) {
      return undefined;
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_, spaceId, environmentId = 'master', entryId] = urn.match(regExp) || [];

    return { spaceId, environmentId, entryId };
  }

  /**
   * [makeEntryObject description]
   *
   * @param   {any}       item             [item description]
   * @param   {string[]}  itemEntryPoints  [itemEntryPoints description]
   *
   * @return  {[]}                         [return description]
   */
  private makeEntryObject(item: any, itemEntryPoints: string[]) {
    if (!Array.isArray(itemEntryPoints)) {
      return item;
    }
    const entryPoints = Object.keys(item).filter(ownKey => itemEntryPoints.indexOf(ownKey) !== -1);

    return entryPoints.reduce((entryObj, entryPoint) => {
      entryObj[entryPoint] = item[entryPoint];
      return entryObj;
    }, {} as any);
  }

  /**
   * [cleanUpLinks cleans the unresolved links]
   *
   * @param   {any}  input  [input description]
   *
   * @return  {[type]}      [return description]
   */
  private cleanUpLinks(input: any) {
    if (Array.isArray(input)) {
      return input.filter(val => val && val !== this.UNRESOLVED_LINK);
    }
    for (const key in input) {
      if (input[key] === this.UNRESOLVED_LINK) {
        delete input[key];
      }
    }
    return input;
  }

  /**
   * [lookupInEntityMap generate the response using orchestration space client id]
   *
   * @param   {any}  linkData  [linkData description]
   *
   * @return  {[type]}         [return description]
   */
  private async lookupInEntityMap(linkData: any) {
    const { entryId, spaceId, environmentId } = linkData;
    const client = this.getClient(spaceId, environmentId);

    let response;
    if (client) {
      response = await client?.withoutUnresolvableLinks.getEntries({
        'sys.id': entryId,
        include: this.DEFAULT_CONTENT_DEPTH,
      });
    }
    return response?.items[0];
  }

  /**
   * [normalizeLink return the spaceId, environmentId, entryId from resourceLink urn to lookupInEntityMap]
   *
   * @param   {any}  link  [link description]
   *
   * @return  {[type]}     [return description]
   */
  private async normalizeLink(link: any) {
    const { type, linkType } = link.sys;
    if (type === 'ResourceLink') {
      const { urn } = link.sys;
      const { spaceId, environmentId, entryId }: any = this.getIdsFromUrn(urn);
      const extractedLinkType = linkType.split(':')[1];

      return await this.lookupInEntityMap({
        linkType: extractedLinkType,
        entryId,
        spaceId,
        environmentId,
      });
    }

    return link;
  }

  /**
   * [walkMutate perform the multiple action with fields]
   *
   * @param   {any}      input    [input description]
   * @param   {any}      mutator  [mutator description]
   *
   * @return  {<input>}           [return description]
   */
  private async walkMutate(input: any, mutator: any) {
    if (this.isResourceLink(input)) {
      return await mutator(input);
    }

    if (input && typeof input === 'object') {
      for (const key in input) {
        if (input.hasOwnProperty(key)) {
          if (input[key].sys && this.isLink(input[key]) && input[key].sys?.linkType === 'Entry') {
            input[key] = this.UNRESOLVED_LINK;
            continue;
          }
          input[key] = await this.walkMutate(input[key], mutator);
        }
      }
    }

    input = this.cleanUpLinks(input);

    return input;
  }

  /**
   * [resolveContentfulResponse process the entry items]
   *
   * @param   {EntryCollection<T>}  response  [response description]
   * @param   {[type]}              T         [T description]
   *
   * @return  {<T>}                           [return description]
   */
  private async resolveContentfulResponse<T extends EntrySkeletonType>(response: EntryCollection<T, undefined>) {
    if (!response.items) {
      return response;
    }
    for (const item of response.items) {
      const entryObject = this.makeEntryObject(item, ['fields']) as Omit<T, 'contentTypeId'>;
      const newData = await this.walkMutate(entryObject, async (link: any) => await this.normalizeLink(link));

      Object.assign(item, newData);
    }

    return response;
  }

  /**
   * [fetchContentEntries fetch the entries from contentful and resolved the reference]
   * Perform different action based on orchestration
   *
   * @param   {EntriesQueries<T>}  params  [params description]
   * @param   {undefined<|>}       T       [T description]
   *
   * @return  {<T><|>}                     [return description]
   */
  async fetchContentEntries<T extends EntrySkeletonType>(params: EntriesQueries<T, undefined> | undefined) {
    if (isEmpty(this.contentfulOrchestrations)) {
      const response = await this.primaryClient.withoutUnresolvableLinks.getEntries<T>(params);
      return response;
    }
    const response = await this.primaryClient.getEntries<T>(params);
    const contentData = await this.resolveContentfulResponse(response);
    return contentData;
  }

  /**
   * [fetchSiteConfig return the site config data]
   *
   * @return  {[type]}  [return description]
   */
  async fetchSiteConfig() {
    try {
      const rawSiteData = await this.fetchContentEntries<SiteContentType>({
        content_type: 'site',
        include: this.DEFAULT_CONTENT_DEPTH as FixedQueryOptions['include'],
      });

      if (rawSiteData.items.length === 0) {
        logger.error(`Site item not found in this space and environment`);
      }

      if (rawSiteData.items.length > 1) {
        logger.error(`More than 1 Site item found. Using first`, JSON.stringify(rawSiteData.items, null, 2));
      }

      return rawSiteData.items[0] as SiteEntryType;
    } catch (e) {
      logger.error('Error', e);
    }
  }

  /**
   * Fetch content page using query
   *
   * @param   {Partial<EntriesQueries>}  query           [query description]
   * @param   {undefined<T>}             EntriesQueries  [EntriesQueries description]
   * @param   {[type]}                   T               [T description]
   *
   * @return  {<EntriesQueries><T>}                      [return description]
   */
  async fetchContentPage(query: Partial<EntriesQueries<ContentPageContentType, undefined>>) {
    try {
      const rawPagesData = await this.fetchContentEntries<ContentPageContentType>({
        content_type: 'contentPage',
        include: this.DEFAULT_CONTENT_DEPTH as FixedQueryOptions['include'],
        ...query,
      });

      if (rawPagesData.items.length === 0) {
        logger.error(`ContentPage item not found: '${query}'`);
      }

      return rawPagesData.items as ContentPageEntryType[];
    } catch (e) {
      logger.error('Error', e);
    }
  }
}
