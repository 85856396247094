// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck

export const setPackagingAndQuantityText = (packaging, numberOfBottles, productType = '', isPrefixIncluded = false) => {
  let output; // Declare the output variable
  let dataToFilter;
  if (packaging || productType) {
    dataToFilter = packaging || (productType !== 'Case' && productType) || '';
  }

  switch (dataToFilter) {
    case 'Case':
      output = 'case';
      break;
    case 'Sundry':
      output = 'can';
      break;
    case 'Can':
      output = 'can';
      break;
    case 'Wine Box':
      output = 'box';
      break;
    case 'Ticket':
      output = 'ticket';
      break;
    default:
      output = 'bottle';
      break;
  }

  if (numberOfBottles > 1) {
    return isPrefixIncluded
      ? `${numberOfBottles} ${output}${output === 'box' ? 'es' : 's'}`
      : `for ${numberOfBottles} ${output}${output === 'box' ? 'es' : 's'}`;
  }

  return `per ${output}`;
};

export const setProductWebType = productWebType => {
  if (productWebType) {
    const lowerCaseProductType = productWebType.toLowerCase();
    if (lowerCaseProductType.includes('wine')) {
      return 'wine';
    } else if (lowerCaseProductType.includes('beer')) {
      return 'beer';
    } else if (lowerCaseProductType.includes('spirit')) {
      return 'spirits';
    } else if (lowerCaseProductType.includes('cider')) {
      return 'cider';
    }
    return 'item';
  }
  return 'item';
};

export const setProductType = (productType, isMixed, hit) => {
  let productTypeName = 'item'; // Default value

  if (productType && !isMixed) {
    const lowerCaseProductType = productType.toLowerCase();

    if (lowerCaseProductType === 'wine') {
      productTypeName = 'wine';
    } else if (lowerCaseProductType === 'nonwine') {
      if (hit?.beer) {
        productTypeName = 'beer';
      } else if (hit?.cider) {
        productTypeName = 'cider';
      } else if (hit?.spirits) {
        productTypeName = 'spirit';
      }
    }
  }

  return productTypeName;
};

export const setPackagingAndQuantitySuffix = (packaging, numberOfBottles) => {
  let output; // Declare the output variable

  switch (packaging) {
    case 'Case':
      output = 'case';
      break;
    case 'Sundry':
      output = 'can';
      break;
    case 'Can':
      output = 'can';
      break;
    case 'Wine Box':
      output = 'box';
      break;
    default:
      output = 'bottle';
      break;
  }

  if (numberOfBottles > 1) {
    return `${output}${output === 'box' ? 'es' : 's'}`;
  }

  return `${output}`;
};

export const setCorrectItemType = productType => {
  let output; // Declare the output variable

  switch (productType) {
    case 'Wine':
      output = 'bottle';
      break;
    case 'NonWine':
      output = 'bottle';
      break;
    case 'Bottle':
      output = 'bottle';
      break;
    case 'Wine Box':
      output = 'box';
      break;
    case 'Case':
      output = 'case';
      break;
    case 'Can':
      output = 'can';
      break;
    case 'Sundry':
      output = 'can';
      break;
    case 'Ticket':
      output = 'ticket';
      break;
    default:
      output = 'bottle';
      break;
  }

  return `${output}`;
};

/**
 * productLinkBuilder
 * @param name: string
 * @param vintage: string
 * @param itemcode: string
 * @param giftFlag: booleen
 * @returns string
 */
export const productLinkBuilder = (name, vintage, itemcode) => {
  let inputString = `${name}`;
  if (vintage) {
    inputString = `${name} ${vintage}`;
  }
  const normalizedString = inputString
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\//g, '');
  const formattedString = normalizedString.toLowerCase().replace(/[\s_]+/g, '-');

  return `/product/${formattedString}/${itemcode}`;
};

export const formatPriceToTwoDecimals = price => {
  if (price || price === 0) {
    return price.toFixed(2);
  }
  return '0.00';
};

export const currency = '£';

/**
 * Converts a string in an array to a formatted String
 * @param category e.g. ['french-white-wine']
 * @returns Nicely formatted string to us as a page title e.g. French White Wine
 */
export const formatCategoryAsTitle = category => {
  let newString = category;

  if (Array.isArray(category)) {
    newString = category.join(', ');
  }

  const formattedString = newString
    .split('-') // Split the string by hyphen
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
    .join(' '); // Join the words with a space

  return formattedString;
};

/**
 * Converts a string in an array to a formatted String
 * @param category e.g. French White Wine
 * @returns Nicely formatted string to us as a canonical link e.g. french-white-wine
 */
export const formatCanonicalLink = category => {
  let newString = category;

  if (Array.isArray(category)) {
    newString = category.join(', ');
  }

  const formattedString = newString.toLowerCase().replace(/ /g, '-');

  return formattedString;
};
