import { Skeleton } from '../Skeleton';
import { css } from '@emotion/react';
import { CarouselEntryType } from 'tsconfig/types.d';
import { get } from 'lodash';
import BannerSkeleton from '../Banner/Banner';
import { ProductCardSkeleton } from '../ProductCard/ProductCard';

interface CarouselSkeletonProps {
  slidesPerBlock: number;
  slideContent: CarouselEntryType['fields']['slides'];
}

interface CarouselSlideSkeletonProps {
  type: string;
}

export const CarouselSkeletonSlide = ({ type }: CarouselSlideSkeletonProps) => {
  switch (type) {
    case 'banner':
      return <BannerSkeleton />;
    case 'productCard':
      return <ProductCardSkeleton />;
    default:
      return <Skeleton />;
  }
};

export const CarouselSkeleton = ({ slidesPerBlock, slideContent }: CarouselSkeletonProps) => {
  const carouselSkeletonContainerCss = css`
    overflow: hidden;
    padding-bottom: 24px;
  `;
  const carouselSkeletonCss = css`
    display: flex;
    flex-direction: row;
    margin-right: calc(-1rem);
  `;
  const carouselSlideSkeletonCss = css`
    position: relative;
    flex: 0 0 ${100 / slidesPerBlock}%;
    padding-right: 1rem;
  `;

  return (
    <div css={carouselSkeletonContainerCss} data-test-id="carouselSkeleton">
      <div css={carouselSkeletonCss}>
        {slideContent.map(content => (
          <div css={carouselSlideSkeletonCss} data-test-id="carouselSlideSkeleton">
            <CarouselSkeletonSlide type={get(content, 'sys.contentType.sys.id') || ''} />
          </div>
        ))}
      </div>
    </div>
  );
};
