/*
Add atom effect to console log all updates
(note this is a Recoil effect and NOT a React effect)
e.g.
export const someAtom = atom({
  key: 'someAtom',
  effects: [ effectConsoleLogAtomUpdates ]
})
TODO: figure out how to embed the Atom name into the log message
*/
export const effectConsoleLogAtomUpdates = ({ onSet }: any) => {
  onSet((data: any) => {
    console.log('Atom SET:', data);
  });
};
