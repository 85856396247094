import { atom } from 'recoil';

//Atom
//----------------------------------------------------------------------
export const miniCartEnabledAtom = atom<boolean>({
  key: 'miniCartEnabledAtom',
  default: false,
});

export const stickyHeaderEnabledAtom = atom<boolean>({
  key: 'stickyHeaderEnabledAtom',
  default: false,
});
