import { useState, useEffect } from 'react';
import { brandConfig } from './brandConfig';
import { globals } from '../../utils/globals';
import { AutocompleteComponents } from '@algolia/autocomplete-js';

export type ProductItemProps = {
  components: AutocompleteComponents;
  hit: any;
};

export const ProductItem = ({ hit, components }: ProductItemProps) => {
  const brand = brandConfig();

  const [imageSrc, setImageSrc] = useState('');

  const handleImage = (itemCode: string) => {
    if (hit.productType === 'Case') {
      return `${brand?.imagePathForCases}/product/${itemCode}${brand?.imageTypeForCases}`;
    }
    return `${brand?.imagePathForProducts}/product/${itemCode}${brand?.imageType}`;
  };

  // Use useEffect to update imageSrc when hit.itemCode changes
  useEffect(() => {
    const getCorrectImage = handleImage(hit.itemCode);
    setImageSrc(getCorrectImage);
  }, [hit.itemCode]);

  const handleImageError = () => {
    if (hit.productType === 'Case') {
      setImageSrc(`${brand?.defaultImagePathForCases}`);
    } else {
      setImageSrc(`${brand?.defaultImagePathForProducts}`);
    }
  };

  return (
    <div className="aa-ItemWrapper">
      <div className="aa-ItemContent">
        <div
          className={`aa-ItemIcon aa-ItemIcon--alignTop ${globals.country === 'us' ? 'us-results-image' : 'results-image'}`}
        >
          <img src={imageSrc} alt={hit.productName} onError={handleImageError} />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">
            {components.Highlight({
              hit: hit,
              attribute: 'productName',
            })}{' '}
            {hit.vintage}
          </div>
        </div>
      </div>
    </div>
  );
};
