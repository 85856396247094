import aa from 'search-insights';
import { globals } from './globals';

// Flag to track initialization
let isInitialized = false;

/**
 * Initialize Algolia Analytics (aa) if not already initialized
 */
const initializeAA = () => {
  if (!isInitialized) {
    aa('init', {
      appId: globals.algoliaAppId, // Replace with your Algolia App ID
      apiKey: globals.algoliaSearchKey, // Replace with your Algolia API Key
    });
    isInitialized = true; // Set the flag to prevent reinitialization
  }
};

export const handleAlgoliaEventForAddToCart = (itemCode: string, quantity: string, hit: any) => {
  const queryID = localStorage.getItem('queryID');

  if (queryID) {
    // Ensure Algolia Analytics is initialized
    initializeAA();

    const itemQty = parseInt(quantity);
    const itemDiscount = hit.skus[0].buyersRRP - hit.skus[0].salePrice || 0;

    aa('addedToCartObjectIDsAfterSearch', {
      eventName: 'Product Added To Cart',
      index: `${globals.algoliaIndexName}`,
      queryID,
      objectIDs: [itemCode],
      objectData: [
        {
          // The discount value for this item, if applicable
          discount: itemDiscount.toFixed(2),
          // The price value for this item (minus the discount)
          price: hit.skus[0].salePrice.toFixed(2),
          // How many of this item were added
          quantity: itemQty,
        },
      ],
      currency: globals.country === 'us' ? 'USD' : 'GBP',
    });
  }
};

// THE FOLLOWING FUNCTION IS NOT USED IN THE CURRENT IMPLEMENTATION

// TODO: Check if this component is still required // Used just on the unlimited button component
export const handleAlgoliaAddToCartEvent = (queryID: string, eventName: string, itemCode: string) => {
  // Ensure Algolia Analytics is initialized
  initializeAA();

  aa('convertedObjectIDsAfterSearch', {
    eventName,
    index: `${globals.algoliaIndexName}`,
    queryID,
    objectIDs: [itemCode],
  });
};

// Algolia click event handler
export const handleAlgoliaClickEvent = (queryID: string, eventName: string, sendEvent: any, hit: any) => {
  // Set the queryID in localStorage
  localStorage.setItem('queryID', queryID);

  // Call the sendEvent function
  sendEvent('click', hit, eventName);
};
