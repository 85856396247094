import { useEffect } from 'react';

/**
 * Custom hook that saves the Algolia query ID to the local storage.
 * @param queryID - The query ID to be saved.
 */
export function useSaveQueryID(queryID: string | undefined) {
  useEffect(() => {
    if (queryID) {
      localStorage.setItem('queryID', queryID);
    }
  }, [queryID]);
}