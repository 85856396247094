import { globals } from 'common-ui';

/**
 * Returns the URL for the small product image based on the item code.
 * @param itemCode - The item code of the product.
 * @returns The URL of the small product image.
 */
export const smallProductImageUrl = (itemCode: string) => {
  if (globals.country === 'us') {
    return `${globals.publicHost}/images/us/en/product/${itemCode}.png`;
  }
  if (globals.country === 'au' && globals.brandId !== 'lwnz') {
    return `${globals.publicHost}/images/au/en/product/${itemCode}.png`;
  }
  if (globals.country === 'nz' || globals.brandId === 'lwnz') {
    return `${globals.publicHost}/images/nz/en/product/${itemCode}.png`;
  }
  return `${globals.publicHost}/images/uk/en/law/product/${itemCode}.png`;
};

/**
 * Returns the URL for the large product image based on the item code.
 * @param itemCode - The item code of the product.
 * @returns The URL of the large product image.
 */
export const largeProductImageUrl = (itemCode: string) => {
  if (globals.country === 'us') {
    return `${globals.publicHost}/images/us/en/product/${itemCode}.png`;
  }
  if (globals.country === 'au' && globals.brandId !== 'lwnz') {
    return `${globals.publicHost}/images/au/en/product/${itemCode}b.png`;
  }
  if (globals.country === 'nz' || globals.brandId === 'lwnz') {
    return `${globals.publicHost}/images/nz/en/product/${itemCode}b.png`;
  }
  return `${globals.publicHost}/images/uk/en/law/product/${itemCode}b.png`;
};

/**
 * Returns the URL for the product flag image based on the country name.
 * @param countryName - The name of the country.
 * @returns The URL of the product flag image.
 */
export const productFlagImageUrl = (countryName: string) => {
  return `${globals.publicHost}/images/${globals.country}/en/common/icons/flags/${countryName}.svg`;
};

/**
 * Returns the URL for the producer image for the country.
 * @param producerImage - The name of the producer image.
 * @returns The URL of the producer image.
 */
export const productProducerImageUrl = (producerImage: string) => {
  if (globals.country === 'us') {
    return `${globals.publicHost}/images/uk/en/law/tastingnote/information/${producerImage}.jpg`;
  }
  return `${globals.publicHost}/images/${globals.country}/en/law/tastingnote/information/${producerImage}.jpg`;
};

export const productMapImageUrl = (producerImage: string) => {
  return `${globals.publicHost}/images/${globals.country}/en/law/tastingnote/map/${producerImage}.jpg`;
};
