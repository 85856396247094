/**
 * Returns correct media query for device type
 * @param {String} name
 * @returns {String}
 */

const EmotionBreakpoint = (name: string) => {
  let mediaQuery;

  switch (name) {
    case 'desktop':
      mediaQuery = '@media (min-width: 992px)';
      break;
    case 'notDesktop':
      mediaQuery = '@media (max-width: 991px)';
      break;
    case 'tablet':
      mediaQuery = '@media (min-width: 768px) and (max-width: 991px)';
      break;
    case 'mobile':
      mediaQuery = '@media (max-width: 767px)';
      break;
    case 'notMobile':
      mediaQuery = '@media (min-width: 768px)';
      break;
    default:
      mediaQuery = '@media (min-width: 768px)';
      break;
  }

  return mediaQuery;
};

export default EmotionBreakpoint;
