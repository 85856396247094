import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { selectUserDetails } from 'common-state';
import { globals } from 'common-ui';

const UK_CHECK_OUT_REDIRECT = '/jsp/checkout/uk/checkout.jsp#/shipping';
const US_CHECK_OUT_REDIRECT = '/jsp/checkout/us/index.jsp';
const APAC_CHECK_OUT_REDIRECT = '/jsp/checkout/au/common/checkout/index.jsp';
const LOGIN_REDIRECT = '/jsp/curity/login.jsp?checkout=true';

/**
 * Returns the checkout redirect URL based on the user's login type and country.
 *
 * @return {string} The checkout redirect URL.
 */
export const useCheckoutRedirect = (): string => {
  const { loginType } = useRecoilValue(selectUserDetails);
  const { country } = globals;

  const checkoutRedirectUrl = useMemo(() => {
    if (loginType === 'hard_logged') {
      switch (country) {
        case 'us':
          return US_CHECK_OUT_REDIRECT;
        case 'uk':
          return UK_CHECK_OUT_REDIRECT;
        case 'au':
          return APAC_CHECK_OUT_REDIRECT;
        default:
          return LOGIN_REDIRECT;
      }
    }
    return LOGIN_REDIRECT;
  }, [loginType, country]);

  return checkoutRedirectUrl;
};
