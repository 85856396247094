import { atom, selector } from 'recoil';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import { CaseOrderHistory, WinePlanTypes } from 'tsconfig/types';

// Storing the upcomming case details in wine plan screen
export const winePlanUpcomingCaseAtom = atom<WinePlanTypes>({
  key: 'winePlanUpcomingCaseAtom', // It saves the upcoming case details data
  default: undefined,
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------

export const selectUpcomingCaseData = selector({
  key: 'selectUpcomingCaseData',
  get: ({ get }) => {
    try {
      const upcomingCaseData = get(winePlanUpcomingCaseAtom);
      if (!!upcomingCaseData) {
        return upcomingCaseData;
      }
      return undefined;
    } catch (error) {
      throw new Error('Something went wrong');
    }
  },
});

export const caseOrderHistoryAtom = atom<CaseOrderHistory>({
  key: 'caseOrderHistoryAtom', // It saves the case order history data
  default: undefined,
  effects: [effectConsoleLogAtomUpdates],
});

export const selectCaseOrderHistoryData = selector({
  key: 'selectCaseOrderHistoryData',
  get: ({ get }) => {
    try {
      const caseOrderHistoryData = get(caseOrderHistoryAtom);
      if (!!caseOrderHistoryData) {
        return caseOrderHistoryData;
      }
      return undefined;
    } catch (error) {
      throw new Error('Something went wrong');
    }
  },
});
