import React from 'react';

const PlayIcon = () => {
  return (
    <svg
      width="89"
      height="90"
      viewBox="0 0 89 90"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect x="1" y="1.302" width="87" height="87" rx="43.5" stroke="white" strokeWidth="2" />
      <rect x="24" y="20.302" width="49" height="49" fill="url(#pattern0_5177_716)" />
      <defs>
        <pattern id="pattern0_5177_716" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_5177_716" transform="scale(0.0208333)" />
        </pattern>
        <image
          id="image0_5177_716"
          width="48"
          height="48"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAArdJREFUaEPVmY9R1UAQh3crECpQK0Aq0A6wA7ACoAKhAqECoAOtQKhAS9AKgArWfM4ecy8k817uT3K5GYbweMn8vtu73+5tVFY+dOX6ZRTAzI5F5J2IPInInaryu7nxCsDM9kTkp4h8iNQi/kpErlsDGQJA/CcR+dtF4NavPzoMIBeqet1KKDYAfPYfReSZ5RNm28wAuugiE0D+OMjd0iB9AIQSgQdV5XpjOAhL6cD/AciX7rv3S4FMAggizezEI/LWPwPgcgmQJIAI5MxB3kQgRITIzDKyAFDo+wYQfgIIm5+IVAfJBoiigf2y0U+jqefvqtZbDCACIfkhnETIqJpDigNEICRCHCvOIWeqWtR6qwFEIFVzSHWACOSzR6So9c4GUCuHzA5Q2noXAehZ71AOOd+16l0UoIT1NgHQA8F6j+IcoqqXY3VJUwBbrJdl9b0P0iRADyQu3ykUqbNeRtMAEQgb/RtlSXca3F8dgFvvbz9IHaoq1//HKiLgAL+80bA+ADOjRGcvPKsqZfs69oCfwW+8P4XodWxiF/7VWzoIp8VzMnTmbmoPmBmHIdyGypVBe4c+FMtncDQB4MKZcbodQTiir7bVRIsCeEOADcoRNAxObJzcdurFLgIQCSdBBVdBOMtlUidjdgDvejPjrHfGg8/4S3IaW+9Dn88GMCKcGc9qS1YHGLFEhG8UZVNmfZZEZma0VbDE0CTGy4sJDxDFI5BjiSlRKAbgzsKMBy9HDyeprV6eIrxYBEpaYgpIVgS8SsQSg5f/cEuc5OUpwrMiUMsSU0AmRWCgvB2tElPEpNwz9pKPOuR97yVfv7wtbonZADygm2UyIy1x1nF4zRq8fGt5myIi556h98RsSCDCm8hJ5W2OmJR7XwGEh5gZhwqyKcvpdtfyNkVEzj2jADkPnfPe1QP8AydvzEBZuEYWAAAAAElFTkSuQmCC"
        />
      </defs>
    </svg>
  );
};

export default React.memo(PlayIcon);
