import { useEffect, useRef } from 'react';

//For a useEffect you only want to call once. Normally useEffect(()=>{...},[]) is enough
//BUT useEffect in 1) Nextjs with 2) react 18 & 3) in strict mode will call useEffect twice when
//running in dev mode. see https://github.com/reactwg/react-18/discussions/18
//Hence we can use a ref to ensure a single call (in dev mode). Prod mode should call once either
//way (but we want dev mode to be consistent)
export const useEffectOnce = (effectFunction: any, params?: any | undefined) => {
  const hasRun = useRef(false);
  useEffect(() => {
    if (hasRun.current === false) {
      hasRun.current = true;
      effectFunction();
    }
  }, params);
};
