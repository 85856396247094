import { GlobalSettings, ContentfulApiInitConfig } from 'tsconfig/types.d';
import { ContentfulClient } from './contentful/contentfulClient';
import { compressContentfulJson, logger } from 'common-ui';

//make sure all linked 'layout' child items are returned as part of query
export const initContentfulAPIWithGlobalSettings = (globalSettings: GlobalSettings) => {
  return initContentfulAPI({
    space: globalSettings.contentfulSpace as string,
    environment: globalSettings.contentfulEnvironment as string,
    accessToken: (globalSettings.contentfulPreviewMode
      ? globalSettings.contentfulPreviewToken
      : globalSettings.contentfulToken) as string,
    host: globalSettings.contentfulPreviewMode ? 'preview.contentful.com' : 'cdn.contentful.com',
    contentfulOrchestration: globalSettings.contentfulOrchestration as string,
    isPreview: globalSettings.contentfulPreviewMode as boolean,
  });
};

export const initContentfulAPI = (config: ContentfulApiInitConfig) => {
  const contentfulClient = new ContentfulClient(config);
  const client = contentfulClient.primaryClient;

  const processResponse = (response: any) => {
    if (config.isPreview) {
      return response;
    }
    return compressContentfulJson(response);
  };

  return {
    //fetches a list of all Content Pages
    fetchListOfAllContentPages: async () => {
      try {
        const rawPagesData = await client.withoutUnresolvableLinks.getEntries({
          content_type: 'contentPage',
          //no child items - we only want the list of pages
          include: 0,
        });
        return processResponse(rawPagesData.items);
      } catch (e) {
        logger.error('Error', e);
      }
    },

    fetchSiteConfig: async () => {
      const siteConfig = await contentfulClient.fetchSiteConfig();
      return processResponse(siteConfig);
    },
    fetchContentPageForSlug: async (slug: string) => {
      const page = await contentfulClient.fetchContentPage({ 'fields.slug': slug });
      if (page && page?.length > 1) {
        logger.error(`More than 1 ContentPage item found for slug '${slug}'. Using first`, JSON.stringify(page, null, 2));
      }
      return processResponse(page?.[0]);
    },

    // fetches a single Content Page by its ID
    fetchContentPageForId: async (id: string) => {
      const page = await contentfulClient.fetchContentPage({ 'fields.id': id });
      if (page && page?.length > 1) {
        logger.error(`More than 1 ContentPage item found for id '${id}'. Using first`, JSON.stringify(page, null, 2));
      }
      return page?.[0];
    },
  };
};
