import Image, { ImageLoaderProps } from 'next/image';
import { useEffect, useState } from 'react';

/**
 * Props for the ProductImage component.
 */
interface ProductImageProps {
  src: string; // The source URL of the image.
  alt: string; // The alternative text for the image.
  width?: number; // The width of the image.
  height?: number; // The height of the image.
  fill?: boolean; // Whether the image should fill its container.
  priority?: boolean; // Whether the image is considered high priority.
  productType?: string; // The type of the product.
  genericImage?: string; // Indicate which is generic image
  objectFit?: 'contain' | 'cover'; // The object-fit property of the image.
}

/**
 * A component for displaying product images in UI.
 */
export const ProductImage: React.FC<ProductImageProps> = props => {
  const {
    src,
    alt,
    fill = false,
    width,
    height,
    productType,
    priority = false,
    genericImage = '',
    objectFit = 'contain',
  } = props;

  const [error, setError] = useState(false);
  const [genericImageError, setGenericImageError] = useState(false);
  const comingSoonDefaultImage =
    productType === 'Case' ? '/next/images/default_images/case.png' : '/next/images/default_images/bottle.png';
  const [defaultImage, setDefaultImage] = useState(comingSoonDefaultImage);

  useEffect(() => {
    if (error && !genericImageError && genericImage != '') {
      setDefaultImage(genericImage);
    } else {
      setDefaultImage(comingSoonDefaultImage);
    }
  }, [error, genericImageError, genericImage]);

  const handleError = () => {
    if (error && !genericImageError) {
      setGenericImageError(true);
    }
    setError(true);
  };

  const imageLoader = ({ src, width, quality }: ImageLoaderProps) => {
    return `${src}?w=${width}&q=${quality || 75}`;
  };

  if (fill) {
    return (
      <div>
        <Image
          src={!error ? src : defaultImage}
          alt={alt}
          title={alt}
          onError={handleError}
          fill
          loader={imageLoader}
          sizes="100vw"
          style={{ objectFit: objectFit }}
          priority={priority}
        />
      </div>
    );
  }

  return (
    <div>
      <Image
        src={!error ? src : defaultImage}
        loader={imageLoader}
        alt={alt}
        title={alt}
        priority={priority}
        onError={handleError}
        width={width}
        height={height}
      />
    </div>
  );
};
