import { DLClickEventProps } from 'tsconfig/types';
import { adobeDataLayer } from './AdobeDataLayer';

export const handleDLMiniCartClick = (clickType: string, clickSource: string) => {
  const pageType = adobeDataLayer.getPageType();

  const launchMiniCart: DLClickEventProps = {
    clickAction: 'launch mini-cart',
    clickDescription: `${pageType}:launch mini-cart`,
    clickLocation: `${pageType}`,
    clickName: 'add to cart',
    clickText: 'add to cart',
    errorMessage: '',
  };

  const closeMiniCartClick: DLClickEventProps = {
    clickAction: 'close mini-cart',
    clickDescription: `mini-cart: close`,
    clickLocation: `mini-cart`,
    clickName: 'mini-cart click',
    clickText: 'close icon',
    errorMessage: '',
  };

  const viewMiniCartClick: DLClickEventProps = {
    clickAction: 'view cart button click',
    clickDescription: `mini cart: view cart`,
    clickLocation: `mini-cart`,
    clickName: 'mini-cart click',
    clickText: 'view cart',
    errorMessage: '',
  };

  const checkoutMiniCartClick: DLClickEventProps = {
    clickAction: 'checkout button click',
    clickDescription: `mini-cart: checkout`,
    clickLocation: `mini-cart`,
    clickName: 'mini-cart click',
    clickText: 'checkout',
    errorMessage: '',
  };

  const continueShoppingtMiniCartClick: DLClickEventProps = {
    clickAction: 'continue shopping link click',
    clickDescription: `mini-cart: continue shopping`,
    clickLocation: `mini-cart`,
    clickName: 'mini-cart click',
    clickText: 'continue shopping',
    errorMessage: '',
  };

  const viewInCarttMiniCartClick: DLClickEventProps = {
    clickAction: 'view in cart link click',
    clickDescription: `mini-cart: view in cart`,
    clickLocation: `mini-cart`,
    clickName: 'mini-cart click',
    clickText: 'view in cart',
    errorMessage: '',
  };

  let click: DLClickEventProps = {};

  switch (clickSource) {
    case 'launchMiniCart':
      click = launchMiniCart;
      break;
    case 'closeMiniCartClick':
      click = closeMiniCartClick;
      break;
    case 'viewMiniCartClick':
      click = viewMiniCartClick;
      break;
    case 'checkoutMiniCartClick':
      click = checkoutMiniCartClick;
      break;
    case 'continueShoppingtMiniCartClick':
      click = continueShoppingtMiniCartClick;
      break;
    case 'viewInCarttMiniCartClick':
      click = viewInCarttMiniCartClick;
      break;
    default:
      return;
  }
  adobeDataLayer.clickEvent(clickType, click);
};
