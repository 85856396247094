import { useEffect, useState } from 'react';
import {
  RecoilInit,
  RecoilInitProps,
  useActionAddToCart,
  useCartGetAddedProduct,
  useInitCartState,
  miniCartEnabledAtom,
  stickyHeaderEnabledAtom,
} from 'common-state';
import { logger, globals, useEffectOnce } from 'common-ui';
import { useRecoilValue } from 'recoil';
import { CallbackFunction } from 'tsconfig/types';

export const RecoilInitEmbeddedWrapper = ({ children }: RecoilInitProps) => {
  const { showProductAdded, showProductRemoved } = useCartGetAddedProduct();
  const addToCart = useActionAddToCart();
  const [isStickyHeaderVisible, setIsStickyHeaderVisible] = useState(false);
  const getUpdatedCart = useInitCartState();
  const isMiniCartEnabled = useRecoilValue(miniCartEnabledAtom);
  const isStickyHeaderEnabled = useRecoilValue(stickyHeaderEnabledAtom);

  const preventDefault = (event: { preventDefault: CallbackFunction }) => {
    event.preventDefault();
  };

  const handleCartBtnClick = () => {
    if (window.miniCart && typeof window.miniCart.toggleMiniCart === 'function') {
      window.miniCart.toggleMiniCart();
    } else {
      logger.error('miniCart.toggleMiniCart() is not defined.');
    }
  };

  // This useEffect integrates New Header cart icon with JSP MiniCart Drawer
  useEffect(() => {
    if (window) {
      window.isMiniCartEnabled = isMiniCartEnabled;
    }
    if (
      window.location.pathname !== '/jsp/checkout/common/shoppingcart.jsp' &&
      isMiniCartEnabled &&
      !globals?.enableNewMiniCart
    ) {
      const anchorTag = document.querySelector('.header-cart-link');
      const cartBtn = document.querySelector('.header-cart-count-container');

      anchorTag?.addEventListener('click', preventDefault);
      cartBtn?.addEventListener('click', handleCartBtnClick);

      return () => {
        anchorTag?.removeEventListener('click', preventDefault);
        cartBtn?.removeEventListener('click', handleCartBtnClick);
      };
    }
  }, [isMiniCartEnabled]);

  // Below two useEffect looks for the sticky header and add click event listener
  useEffect(() => {
    const mainHeader = document.querySelector('#mainHeaderNavBar') as HTMLElement;

    const handleScroll = () => {
      if (window.scrollY >= mainHeader?.offsetHeight + 10) {
        setIsStickyHeaderVisible(true);
      } else {
        setIsStickyHeaderVisible(false);
      }
    };
    if (
      window.location.pathname !== '/jsp/checkout/common/shoppingcart.jsp' &&
      isMiniCartEnabled &&
      isStickyHeaderEnabled
    ) {
      window.addEventListener('scroll', handleScroll);
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isMiniCartEnabled, isStickyHeaderEnabled]);

  useEffect(() => {
    const anchorTag = document.querySelector('.cart > .header-cart-link');
    const cartBtn = document.querySelector('.cart');

    if (isStickyHeaderVisible) {
      anchorTag?.addEventListener('click', preventDefault);
      cartBtn?.addEventListener('click', handleCartBtnClick);
    }

    return () => {
      anchorTag?.removeEventListener('click', preventDefault);
      cartBtn?.removeEventListener('click', handleCartBtnClick);
    };
  }, [isStickyHeaderVisible]);

  const dispatchAddToCartStatus = (message: string, originalEvent: CustomEvent, error: boolean) => {
    document.dispatchEvent(
      new CustomEvent('aws-add-to-cart-status', {
        detail: { message, originalEvent, error },
      }),
    );
  };

  const addToCartCallback = async (event: CustomEvent<[{ itemCode: string; quantity: string }]>) => {
    try {
      if (!event?.detail) {
        throw new Error('Something went wrong. Please try again.');
      }
      await addToCart(event?.detail);
      // Dispatch a new event with the status of the add to cart action
      dispatchAddToCartStatus('Success', event, false);
    } catch (error: any) {
      // Dispatch a new event with the status of the add to cart action
      dispatchAddToCartStatus(error, event, true);
    }
  };

  const handleAddToCart = (event: Event) =>
    addToCartCallback(event as CustomEvent<[{ itemCode: string; quantity: string }]>);

  useEffectOnce(() => {
    document.addEventListener('aws-add-to-cart', handleAddToCart);
    document.addEventListener('item-added-into-cart-successful', showProductAdded);
    document.addEventListener('event-remove-cart-item', showProductRemoved);
    document.addEventListener('event-removeUpdate-cart-item', showProductRemoved);
    document.addEventListener('event-update-cart-item', () => getUpdatedCart());
    document.addEventListener('event-remove-cart-item', () => getUpdatedCart());

    return () => {
      document.removeEventListener('aws-add-to-cart', handleAddToCart);
      document.removeEventListener('item-added-into-cart-successful', showProductAdded);
      document.removeEventListener('event-remove-cart-item', showProductRemoved);
      document.removeEventListener('event-removeUpdate-cart-item', showProductRemoved);
      document.removeEventListener('event-update-cart-item', () => getUpdatedCart());
      document.removeEventListener('event-remove-cart-item', () => getUpdatedCart());
    };
  }, []);

  return <RecoilInit>{children}</RecoilInit>;
};
