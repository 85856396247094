import { atom, selector, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { initCommerceApiWithGlobals } from 'common-api';
import { effectConsoleLogAtomUpdates } from '../helpers/debug';
import type {
  VineCart,
  LineItem,
  ProductProps,
  cartApiErrorProps,
  CartResponse,
  CartItemProps,
  CurrentCartCookieValues,
  ErrorResponse,
} from 'tsconfig/types.d';
import { NotificationType, notificationsAtom } from './notificationsAtom';
import { isBrowser } from '../helpers/utils';
import { logger, globals, handleDLProductClickEvent, GENERIC_ERROR_MESSAGE } from 'common-ui';
import { getCookieValue } from 'common-ui/src/utils/getCookieValue';
import { authAtom, getAnonymousToken, refreshAuthToken, reloadAuthToken } from './authAtom';
import { getRecoil, setRecoil } from 'recoil-nexus';
import { notificationMessage } from '../content/notificationMessage';
import { selectUserDetails } from './userAtom';

export const commerceApiExtraConfig = (publicCsrfValue = '') => ({
  webApiExtraHeaders: {
    [globals.authAndWebApiCsrfHeaderName as string]: publicCsrfValue,
  },
});

/**
 * Atom for managing cart error state.
 *
 * This atom stores information about any errors that occur during cart operations.
 * - key: 'cartError' - unique identifier for the atom
 * - default: { isError: false, errorMessage: '' } - initial state with no errors
 */
export const cartError = atom<cartApiErrorProps>({
  key: 'cartError',
  default: {
    isError: false,
    errorMessage: '',
  },
});

//Atom
//----------------------------------------------------------------------
export const cartAtom = atom<VineCart>({
  key: 'cartAtom', // unique ID (with respect to other atoms/selectors)
  default: undefined, // default value (aka initial value)
  effects: [effectConsoleLogAtomUpdates],
});

//Selectors
//----------------------------------------------------------------------
export const selectCartDetails = selector({
  key: 'selectCartDetails', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const cart: VineCart = get(cartAtom);
    if (typeof cart !== 'undefined' && typeof (cart as VineCart).totalLineItems !== 'undefined') {
      // ATG Cart
      let cartTotal = cart.totalPrice.amount;

      //TODO: Remove once backend fix the shipping for new cart API
      // New AWS cart - Subtract shipping cost from header mini cart
      if (globals.useNewCartAPI) {
        const shippingPrice = cart?.shippingInformation?.price?.amount || 0;
        cartTotal = Math.max(cart.totalPrice.amount - shippingPrice, 0);
      }

      return {
        itemsCount: cart.totalLineItems,
        cartTotal,
        hasQuantity: cart.totalLineItems > 0,
      };
    }
    return {
      itemsCount: 0,
      cartTotal: 0,
      hasQuantity: false,
    };
  },
});

/**
 * Cart data selector.
 *
 * This selector retrieves the cart data from the Recoil state.
 *
 * - key: 'cartData'
 * - get: Function - Fetches cart data from 'cartAtom' atom.
 */
export const selectCartData = selector({
  key: 'selectCartData',
  get: ({ get }) => {
    try {
      return get(cartAtom);
    } catch (error) {
      throw new Error('Something went wrong');
    }
  },
});

/**
 * Selector for retrieving cart error data.
 *
 * This selector retrieves the current state of cart error information from the 'cartError' atom.
 * - key: 'cartErrorData' - unique identifier for the selector
 * - get: Retrieves the current value of the 'cartError' atom
 *    - If successful, returns the cart error data
 *    - If an error occurs during retrieval, throws an error with the message 'Something went wrong'
 */
export const selectCartError = selector({
  key: 'selectCartError',
  get: ({ get }) => {
    try {
      // Retrieve current cart error data
      return get(cartError); // Return retrieved cart error data
    } catch (error) {
      throw new Error('Something went wrong'); // Throw an error if something unexpected occurs during retrieval
    }
  },
});

//Hooks
//----------------------------------------------------------------------
export const useInitCartState = () => {
  const initCartStateWithAtgLegacyApi = useInitCartStateWithAtgLegacyApi();
  const initCartStateWithWebApi = useInitCartStateWithWebApi();

  return async () => {
    if (isBrowser()) {
      if (globals.useNewCartAPI) {
        return initCartStateWithWebApi();
      }
      return initCartStateWithAtgLegacyApi();
    }
    logger.log('useInitCartState skipping fetch as not in browser');
  };
};

export function useInitCartStateWithAtgLegacyApi() {
  const [currentCartData] = useRecoilState(cartAtom);

  return async () => {
    if (currentCartData) {
      logger.log('skipping init fetch of cart data as already defined');
      return;
    }
    await fetchCart();
  };
}

export function useInitCartStateWithWebApi() {
  const currentCartData = useRecoilValue(cartAtom);
  const commonCartError = useCommonCartError();

  return async () => {
    if (!isBrowser()) {
      logger.log('useInitCartStateWithWebApi skipping fetch as not in browser');
      return;
    }

    if (currentCartData) {
      logger.log('skipping init fetch of cart data as already defined');
      return;
    }

    try {
      const { cartId } = getCurrentCartIdFromCookie();
      const authAtomState = getRecoil(authAtom);

      if (!authAtomState?.publicCsrf) {
        logger.error('!token is not present');
      }

      // Check cartID Cookie is present
      if (cartId && cartId !== '') {
        try {
          let newAuthValue;
          // if public CSRF is not available in state, then fetch from /pagereload endpoint
          if (!authAtomState?.publicCsrf) {
            newAuthValue = await reloadAuthToken();
          }

          // If got new csrf token, then fetch cart using that token
          if (newAuthValue?.publicCsrf) {
            // if cartID Cookie is present, then fetch cart using that cart ID
            await fetchCart();
          } else {
            commonCartError(true, GENERIC_ERROR_MESSAGE);
          }
        } catch (error: any) {
          // if error set common Error for the page.
          commonCartError(true, error?.response?.data?.message);
        }
      } else {
        try {
          let newAuthValue;
          // Fetch user login status from userAtom
          const { loginType } = getRecoil(selectUserDetails);

          if (loginType === 'unidentified') {
            // If user is Anonymous, get new token
            newAuthValue = await getAnonymousToken();
          } else {
            // if public CSRF is not available in state, then fetch from /pagereload endpoint
            //TODO: Refactor to reduce complexity
            // eslint-disable-next-line max-depth
            if (!authAtomState?.publicCsrf) {
              newAuthValue = await reloadAuthToken();
            }
            // If user is loggedin, then call /refresh endpoint for tokens to get refreshed
            await refreshAuthToken();
          }

          // if cartID Cookie is not present, then create a new cart. It will generate the cart ID
          if (newAuthValue?.publicCsrf) {
            await createNewCart();
          } else {
            commonCartError(true, GENERIC_ERROR_MESSAGE);
          }
        } catch (error: any) {
          // if error set common Error for the page.
          commonCartError(true, error?.response?.data?.message);
        }
      }
    } catch (e) {
      // if error set common Error for the page.
      commonCartError(true, '');
      logger.error('API ERROR', e);
    }
  };
}

export function useActionAddToCart() {
  const addToCartWithAtgLegacyApi = useAddToCartWithAtgLegacyApi();
  const addToCartWithWebApi = useAddToCartWithWebApi();
  const isNewCartAPI = globals?.useNewCartAPI;

  return async (items: CartItemProps[]) => {
    if (isNewCartAPI) {
      const cartItems = items.map(({ itemCode, quantity }) => ({
        itemCode,
        quantity: Number(quantity), // Ensure quantity is converted to a number
      }));
      return addToCartWithWebApi(cartItems);
    }
    const [{ itemCode, quantity }] = items; // Destructure the first item directly
    return addToCartWithAtgLegacyApi(itemCode, quantity.toString());
  };
}

export function useAddToCartWithAtgLegacyApi() {
  const [, setCartData] = useRecoilState(cartAtom);
  const setNotification = useSetRecoilState(notificationsAtom);

  return async (productSku: string, quantity: string) => {
    try {
      const commerceApi = initCommerceApiWithGlobals();
      const newCartData = await commerceApi.Cart.add(productSku, quantity);
      setCartData(newCartData);
      const notification = cartNotification(productSku, quantity);
      setNotification([notification]);
    } catch (e) {
      logger.error('API ERROR', e);
      throw e;
    }
  };
}

// Function to create notification object
export function cartNotification(productSku: string, quantity: string) {
  const isNewMiniCartEnabled = globals.enableNewMiniCart;
  const notificationTimeout = isNewMiniCartEnabled ? null : 5000;
  const notificationExpires = isNewMiniCartEnabled ? Infinity : Date.now() + 5000;

  const notificationBase = {
    type: NotificationType.ADD_TO_CART,
    timeout: notificationTimeout,
    message: notificationMessage[NotificationType.ADD_TO_CART],
    expires: notificationExpires,
  };

  // Conditionally add miniCart object if the new mini cart is enabled
  return {
    ...notificationBase,
    ...(isNewMiniCartEnabled &&
      productSku &&
      quantity && {
        miniCart: {
          itemCode: productSku,
          quantity: quantity,
        },
      }),
  };
}

export function useAddToCartWithWebApi() {
  const [, setCartData] = useRecoilState(cartAtom);
  const setNotification = useSetRecoilState(notificationsAtom);

  return async (items: CartItemProps[]) => {
    async function doAddToCart() {
      if (!isBrowser()) {
        logger.log('useAddToCartWithWebApi skipping as not in browser');
        return;
      }

      const cartData = getRecoil(cartAtom);

      // If cartData is not available, throw Error
      if (!cartData) {
        throw new Error(GENERIC_ERROR_MESSAGE);
      }

      let authAtomState = getRecoil(authAtom);

      // If public CSRF is not available in state, then fetch from /pagereload endpoint
      if (!authAtomState?.publicCsrf) {
        authAtomState = (await reloadAuthToken()) || authAtomState;
      }

      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
      const { cartId, cartVersion } = getCurrentCartIdFromCookie();
      const productData = {
        version: cartVersion,
        items,
      };

      try {
        const newCartData = await commerceApi.CartPreferences.addItemToCart({
          cartId,
          data: productData,
        });

        const productArray = await getProducts(newCartData);
        if (productArray && productArray.length > 0) {
          setCartData({
            ...newCartData,
            lineItems: productArray,
          });
        } else {
          setCartData(newCartData);
        }

        const [{ itemCode, quantity }] = items;
        const notification = cartNotification(itemCode, quantity.toString());
        setNotification([notification]);
      } catch (error) {
        logger.error('Error occurred:', error);
        throw error; // Reject the promise with the error
      }
    }

    try {
      await doAddToCart();
    } catch (e: any) {
      return commonHandlerForWebApiRequestError(e, doAddToCart);
    }
  };
}

export function useActionBatchAddToCart() {
  const setCartData = useSetRecoilState(cartAtom);
  const [, setNotification] = useRecoilState(notificationsAtom);

  return async (cartItems: any) => {
    const commerceApi = initCommerceApiWithGlobals();
    const newCartData = await commerceApi.Cart.batch(cartItems);
    if (newCartData) {
      setCartData(newCartData);
      const [{ itemCode, quantity }] = cartItems.cartItems.slice(1, 2);
      const notification = cartNotification(itemCode, quantity);
      setNotification([notification]);
    }
  };
}

//This is a hook called from ATG pages when they add to cart and we need to refresh header AND show a 'item added' notification
export function useCartGetAddedProduct() {
  const [, setNotification] = useRecoilState(notificationsAtom);

  const showProductAdded = async (event?: any) => {
    await fetchCart();
    const { itemcode: itemCode, quantity } = event?.detail ?? { itemcode: '', quantity: 0 };
    const notification = cartNotification(itemCode, quantity);
    setNotification([notification]);
  };

  const showProductRemoved = async () => {
    await fetchCart();
    setNotification([
      {
        type: NotificationType.REMOVED_FROM_CART,
        timeout: 5000,
        message: notificationMessage[NotificationType.REMOVED_FROM_CART],
        expires: new Date().getTime() + 5000,
      },
    ]);
  };
  return { showProductAdded, showProductRemoved };
}

// Creates new Cart (only necessary in WebApi)
export async function createNewCart(): Promise<void> {
  async function doCreateCart(): Promise<void> {
    if (!isBrowser()) {
      logger.log('createNewCart skipping as not in browser');
      return;
    }

    let authAtomState = getRecoil(authAtom);

    // If public CSRF is not available in state, then fetch from /pagereload endpoint
    if (!authAtomState?.publicCsrf) {
      authAtomState = (await reloadAuthToken()) || authAtomState;
    }

    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));

    const newCartCreationData = await commerceApi.CartPreferences.createNewCart();
    setRecoil(cartAtom, newCartCreationData);
  }

  try {
    await doCreateCart();
  } catch (e: any) {
    return commonHandlerForWebApiRequestError(e, doCreateCart);
  }
}

// Function to Fetch the latest cart data
export async function fetchCart() {
  if (globals.useNewCartAPI) {
    return fetchCartWithWebApi();
  }
  return fetchCartWithAtgLegacyApi();
}

export async function fetchCartWithAtgLegacyApi() {
  try {
    const commerceApi = initCommerceApiWithGlobals();
    const newCartData = await commerceApi.Cart.get();
    setRecoil(cartAtom, newCartData);
  } catch (e) {
    logger.error('API ERROR', e);
  }
}

export async function fetchCartWithWebApi() {
  async function doFetchCart() {
    if (!isBrowser()) {
      logger.log('fetchCartWithWebApi skipping as not in browser');
      return;
    }

    let authAtomState = getRecoil(authAtom);

    // If public CSRF is not available in state, then fetch from /pagereload endpoint
    if (!authAtomState?.publicCsrf) {
      authAtomState = (await reloadAuthToken()) || authAtomState;
    }

    const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
    const { cartId } = getCurrentCartIdFromCookie();
    const newCartData = await commerceApi.CartPreferences.getMyCart({ cartId });

    // Pass the Cart data to getProducts fn to fetch product details
    const productArray = await getProducts(newCartData);

    // Set the product details in the same array
    if (productArray && productArray.length > 0) {
      setRecoil(cartAtom, {
        ...newCartData,
        lineItems: productArray,
      });
    } else {
      setRecoil(cartAtom, newCartData);
    }
  }

  try {
    await doFetchCart();
  } catch (e: any) {
    return commonHandlerForWebApiRequestError(e, doFetchCart);
  }
}

// Function to fetch Product details
export async function getProducts(newCartData: any) {
  if (!isBrowser()) {
    logger.log('getProducts skipping as not in browser');
    return;
  }

  const commerceApi = initCommerceApiWithGlobals();

  // fetch all products via product BFF
  const productItemCodes = (newCartData as CartResponse)?.lineItems?.map(item => {
    return item.sku.itemCode;
  });

  if (productItemCodes.length > 0) {
    const itemCodeQueryParam = productItemCodes.join(','); // Join itemCodes with a comma
    const products: ProductProps[] = await commerceApi.Product.getAllProductData(itemCodeQueryParam);
    const combinedArray: any = [];
    newCartData?.lineItems?.forEach((cartdata: LineItem) => {
      const matchingItem = products.find(product => product.itemCode === cartdata.sku.productItemCode);
      const combinedItem = { ...cartdata, product: { ...matchingItem } };
      combinedArray.push(combinedItem);
    });
    return combinedArray;
  }

  return;
}

// Function to update the cart Quantity
export function useUpdateCartItemQuantity() {
  const setCartData = useSetRecoilState(cartAtom);

  return async (data: any, prevCount: number, eventType = 'inputUpdate') => {
    async function doUpdateCartItemQuantity() {
      if (!isBrowser()) {
        logger.log('useUpdateCartItemQuantity skipping as not in browser');
        return;
      }

      let authAtomState = getRecoil(authAtom);

      // If public CSRF is not available in state, then fetch from /pagereload endpoint
      if (!authAtomState?.publicCsrf) {
        authAtomState = (await reloadAuthToken()) || authAtomState;
      }

      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
      const { cartId, cartVersion } = getCurrentCartIdFromCookie();
      const newCartData = await commerceApi.CartPreferences.updateCartItem(
        { cartId },
        {
          version: cartVersion,
          ...data,
        },
      );

      const productArray = await getProducts(newCartData);

      const updatedQuantity = data?.items[0]?.quantity;

      // For Adobe Data Layer
      const updatedProduct = productArray.find((item: LineItem) => item?.lineItemId === data?.items[0]?.lineItemId);
      handleDLProductClickEvent(
        eventType === 'increment' || (eventType === 'inputUpdate' && updatedQuantity > prevCount)
          ? 'addToCart'
          : 'removeFromCart',
        updatedProduct?.product,
        updatedQuantity,
        'cart',
      );

      if (productArray && productArray.length > 0) {
        setCartData({
          ...newCartData,
          lineItems: productArray,
        });
      } else {
        setCartData(newCartData);
      }
    }

    try {
      await doUpdateCartItemQuantity();
    } catch (e: any) {
      return commonHandlerForWebApiRequestError(e, doUpdateCartItemQuantity);
    }
  };
}

// Function to Delete cart item
export function useDeleteCartItem() {
  const setCartData = useSetRecoilState(cartAtom);
  const currentCartData = useRecoilValue(cartAtom);

  return async (lineId: string) => {
    async function doDeleteCartItem() {
      if (!isBrowser()) {
        logger.log('useDeleteCartItem skipping as not in browser');
        return;
      }

      let authAtomState = getRecoil(authAtom);

      // If public CSRF is not available in state, then fetch from /pagereload endpoint
      if (!authAtomState?.publicCsrf) {
        authAtomState = (await reloadAuthToken()) || authAtomState;
      }

      const commerceApi = initCommerceApiWithGlobals(commerceApiExtraConfig(authAtomState.publicCsrf));
      const { cartId, cartVersion } = getCurrentCartIdFromCookie();
      const newCartData = await commerceApi.CartPreferences.deleteItemFromCart({
        cartId,
        lineItemId: lineId,
        version: cartVersion,
      });

      const productArray = await getProducts(newCartData);

      // For Adobe Data Layer
      const removedItem = currentCartData?.lineItems?.find((item: LineItem) => item?.lineItemId === lineId);
      if (removedItem && removedItem.product) {
        handleDLProductClickEvent('removeFromCart', removedItem?.product, removedItem?.quantity || 1, 'cart');
      }

      if (productArray && productArray.length > 0) {
        setCartData({
          ...newCartData,
          lineItems: productArray,
        });
      } else {
        setCartData(newCartData);
      }
    }

    try {
      await doDeleteCartItem();
    } catch (e: any) {
      return commonHandlerForWebApiRequestError(e, doDeleteCartItem);
    }
  };
}

//Helper functions
//----------------------------------------------------------------------

// Function to show common cart page error
export function useCommonCartError() {
  const [, setCartApiError] = useRecoilState(cartError);

  return (isError: boolean, errorMessage = '') => {
    setCartApiError({
      isError,
      errorMessage,
    });
  };
}

// Variables to track error count
let errorCount = 0;
let error404Count = 0;

export async function commonHandlerForWebApiRequestError(e: any, doAction?: () => Promise<void>): Promise<void> {
  if (!isBrowser()) {
    logger.log('Skipping fetch as not in browser');
    return;
  }

  const { loginType } = getRecoil(selectUserDetails);
  const { status } = e?.response || {};

  // Handle authorization-related errors (401, 403)
  if ((status === 401 || status === 403) && errorCount < 2) {
    errorCount++;
    return handleAuthError(status, loginType, doAction);
  }

  // Handle 404 (Not Found) errors
  if (status === 404 && error404Count < 2) {
    error404Count++;
    return handleAuthError(404, loginType, createNewCart);
  }

  throw e;
}

async function handleAuthError(status: number, loginType?: string, doAction?: () => Promise<void>): Promise<void> {
  if (status === 403 && loginType !== 'unidentified') {
    return handleRegisteredUser(createNewCart);
  }

  return loginType === 'unidentified' ? handleAnonymousUser() : handleRegisteredUser(doAction);
}

async function handleAnonymousUser(): Promise<void> {
  try {
    const newAuthValue = await getAnonymousToken();

    if (newAuthValue?.publicCsrf) {
      await createNewCart();
    } else {
      throw new Error(GENERIC_ERROR_MESSAGE);
    }
  } catch (error) {
    throw new Error(GENERIC_ERROR_MESSAGE);
  }
}

async function handleRegisteredUser(doAction?: () => Promise<void>): Promise<void> {
  try {
    const refreshSuccess = await refreshAuthToken();
    if (refreshSuccess && doAction) {
      return await doAction();
    }
  } catch (error: unknown) {
    const apiError = error as ErrorResponse;
    if (apiError?.response?.status === 404) {
      commonHandlerForWebApiRequestError(error);
    } else {
      throw new Error(GENERIC_ERROR_MESSAGE);
    }
  }
}

export function getCurrentCartIdFromCookie(): CurrentCartCookieValues {
  const cookieValues = getCookieValue('cart-id')?.split('|') || [];
  return {
    cartId: cookieValues[0],
    cartVersion: parseInt(cookieValues[1], 10),
  };
}

// Cart Offer - Upsell
export async function fetchCartOffer() {
  try {
    if (!isBrowser()) {
      logger.log('fetchCartOffer skipping as not in browser');
      return;
    }
    const commerceApi = initCommerceApiWithGlobals();
    return await commerceApi.Cart.getCartOffer();
  } catch (error) {
    logger.error('API ERROR', error);
    throw error;
  }
}
