// Wrapper for the (Token Auth based) AWS API
// This is the new Auth Token based API that all legacy API services will ultimately be migrated too
import axios from 'axios';
import { generateRandomClientId } from 'common-state/src/helpers/utils';

export type WebApiConfig = {
  urlPrefix: string;
  extraHeaders?: Record<string, string>;
  host: string;
};

export const initWebApi = (config: WebApiConfig) => {
  async function asyncRequest(requestType: string, url: string, data = {}) {
    const requestConfig = {
      method: requestType,
      url: `${config.urlPrefix}/webapi/${url}`,
      headers: {
        siteorigin: config.host,
        'x-dw-brand-domain': config.host,
        'x-dw-client-id': sessionStorage.getItem('sessionUID') || generateRandomClientId(),
        ...config.extraHeaders,
      },
      data: data,
    };

    if (data) {
      requestConfig.data = data;
    }

    const response = await axios.request(requestConfig);
    return response.data;
  }

  return {
    CartPreferences: {
      createNewCart: async () => asyncRequest('POST', `carts/me`),
      addItemToCart: async (params: any) => asyncRequest('POST', `carts/me/${params.cartId}/items`, params.data),
      deleteItemFromCart: async (params: any) =>
        asyncRequest('DELETE', `carts/me/${params.cartId}/items/${params.lineItemId}?version=${params.version}`),
      updateCartItem: async (params: any, data: any) => asyncRequest('PUT', `carts/me/${params.cartId}`, data),
      getMyCart: async (params: any) => asyncRequest('GET', `carts/me/${params.cartId}`),
    },
  };
};
