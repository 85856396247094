import React, { memo } from 'react';
import { HtmlCodeEntryType } from 'tsconfig/types.d';
import { inspectorMode } from 'common-ui';

export type CmsHtmlCodeProps = {
  contentItem: HtmlCodeEntryType;
};

export const CmsHtmlCode = memo((props: CmsHtmlCodeProps) => {
  const { contentItem } = props;

  const parseHtmlContent = (htmlContent: string) => {
    if (typeof window !== 'undefined' && typeof DOMParser !== 'undefined') {
      const parser = new DOMParser();
      const doc = parser.parseFromString(htmlContent, 'text/html');
      return doc.body.innerHTML;
    } 
      return htmlContent;
    
  };

  const sanitizedContent =
    typeof contentItem?.fields?.content === 'string' &&
    (contentItem?.fields?.content as string).replace(/<[^>]*>/g, '').trim()
      ? parseHtmlContent(contentItem?.fields?.content)
      : '';

  return (
    <>
      {sanitizedContent ? (
        <div
          dangerouslySetInnerHTML={{ __html: sanitizedContent || '' }}
          {...inspectorMode(contentItem?.sys?.id, 'content')}
        />
      ) : null}
    </>
  );
});
