import { css, useTheme } from '@emotion/react';
import React, { memo, useEffect } from 'react';
import { NavigationEntryType, LinkEntryType, DLClickEventHandlerProps } from 'tsconfig/types';
import { Grid, Icon, CmsLink } from 'common-ui';
import { useRecoilValue } from 'recoil';
import { selectUserDetails, allLoginType } from 'common-state';

export type NavItemProps = {
  fields: NavigationEntryType['fields'];
  dlClickEventHandler?: DLClickEventHandlerProps;
};

const NavItem = memo(({ fields, dlClickEventHandler }: NavItemProps) => {
  const { title, openAccordionByDefault, content } = fields;
  const [isActive, setIsActive] = React.useState<boolean>(false);

  useEffect(() => {
    if (openAccordionByDefault) {
      setIsActive(true);
    }
  }, []);

  const toggleTab = (tabTitle: string) => {
    setIsActive(prestate => !prestate);
    dlClickEventHandler && dlClickEventHandler(`${isActive ? 'close' : 'open'}:${tabTitle}`, 'accordionMenu');
  };
  const user = useRecoilValue(selectUserDetails);

  const currentLoginType = allLoginType.filter(loginType => loginType !== user?.loginType);

  const theme = useTheme();
  const wrapper = css`
    ${theme.widgets.Footer?.navItems}
  `;

  return (
    <div
      css={wrapper}
      data-test="navigation"
      className={`navItem-navigation ${isActive ? 'navItem-navigation-active' : ''}`}
    >
      <Grid
        align={'center'}
        justify={'between'}
        data-test="navigation-header"
        onClick={() => toggleTab(title)}
        onKeyDown={event => {
          if (event.code === 'Space') {
            event.preventDefault();
            toggleTab(title);
          }
        }}
      >
        <p data-test="navigation-title">{title}</p>
        <div
          role="navigation"
          aria-label={title}
          className={`tab-indicator ${isActive ? 'tab-expand' : 'tab-collapse'}`}
        >
          <Icon kind="chevron-down" size="l" />
        </div>
      </Grid>
      <div className={`tab-panel ${isActive ? 'tab-expand' : 'tab-collapse'}`} data-test="navigation-content">
        <ul>
          {(content as LinkEntryType[])?.map(link => {
            if (currentLoginType.some(value => (link?.fields?.targetingV2 as unknown as string) === value)) {
              return <></>;
            }
            return (
              <li key={link.fields.title}>
                <CmsLink contentItem={link} dlClickEventHandler={dlClickEventHandler} />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
});

export default NavItem;
