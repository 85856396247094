import { useTheme } from '@emotion/react';
import { css } from '@emotion/css';
import { Carousel } from '@mantine/carousel';
import { useScreenLayout, logger, ItemCard } from 'common-ui';
import { memo, useEffect, useState } from 'react';
import { initCommerceApiWithGlobals } from 'common-api';

export const RecentlyViewedProducts = memo(() => {
  const theme = useTheme();
  const { isMobile } = useScreenLayout();
  const [showControls, setShowControls] = useState(false);

  const [recentlyBrowsed, setRecentlyBrowsed] = useState<{ userItems: { product: any }[] }>({ userItems: [] });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const commerceApi = initCommerceApiWithGlobals();
        const data = await commerceApi.User.getRecentlyBrowsed();
        setRecentlyBrowsed(data.response);
      } catch (error) {
        logger.error('Error fetching RecentlyViewedProducts:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let slidestoShow;
      switch (true) {
        case windowWidth < 576:
          slidestoShow = 1;
          break;
        case windowWidth < 768:
          slidestoShow = 2;
          break;
        case windowWidth < 992:
          slidestoShow = 3;
          break;
        case windowWidth < 1200:
          slidestoShow = 4;
          break;
        case windowWidth < 1408:
          slidestoShow = 5;
          break;
        default:
          slidestoShow = 6;
      }

      setShowControls(Array.isArray(recentlyBrowsed?.userItems) && recentlyBrowsed?.userItems.length > slidestoShow);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [recentlyBrowsed?.userItems]);

  const recentlyViewed = css`
    ${theme.widgets.Product?.recentlyViewed}
  `;

  return (
    <div data-testid="recently-viewed" className={`no-print ${recentlyViewed}`}>
      {recentlyBrowsed && recentlyBrowsed?.userItems && recentlyBrowsed?.userItems.length > 0 && (
        <div className="container text-center">
          <h3 data-testid="recently-viewed-title">Recently Viewed</h3>
          <Carousel
            withIndicators={showControls}
            withControls={showControls}
            loop={showControls}
            draggable={showControls}
            slidesToScroll={1}
            slideSize={isMobile ? '100%' : `16.66666%`}
            align="start"
            slideGap="md"
            controlsOffset="md"
            className="recently-viewed-carousel"
            styles={theme.widgets.CmsCarousel}
            breakpoints={[
              { maxWidth: 'xl', slideSize: '20%' },
              { maxWidth: 'lg', slideSize: '25%' },
              { maxWidth: 'md', slideSize: '33.33333%', slideGap: 'sm' },
              { maxWidth: 'sm', slideSize: '50%', slideGap: 'sm' },
              { maxWidth: 'xs', slideSize: '100%', slideGap: 0 },
            ]}
            data-testid={'recently-viewed-carousel'}
          >
            {recentlyBrowsed?.userItems.map(({ product }) => (
              <Carousel.Slide key={product?.id}>
                <ItemCard
                  product={{
                    ...product,
                    productType: product?.mixed ? 'Case' : product?.productType,
                    productName: product?.name,
                  }}
                  showRating={false}
                  showImageZoom={true}
                  showPrice={false}
                  showAddtoCart={false}
                  hideBackgroundColour={true}
                />
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      )}
    </div>
  );
});
