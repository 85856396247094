/**
 * productLinkBuilder
 * @param name: string
 * @param vintage: string
 * @param itemcode: string
 * @param giftFlag: booleen
 * @returns string
 */
import { globals } from 'common-ui';

export const productLinkBuilder = (name: string, vintage: string, itemcode: string, giftFlag = false) => {
  let inputString = `${name}`;
  if (vintage) {
    inputString = `${name} ${vintage}`;
  }
  const normalizedString = inputString
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\//g, '');
  const formattedString = normalizedString.toLowerCase().replace(/[\s_]+/g, '-');

  if (giftFlag && globals.brandId !== 'avy' && globals.country === 'uk') {
    return `/gifts/product/${formattedString}/${itemcode}`;
  }

  return `/product/${formattedString}/${itemcode}`;
};
